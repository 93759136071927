import { Component, OnInit } from '@angular/core';
import { StravaService } from '../../services';
import { DestroyComponent } from '@maplix/ng-utils';
import { catchError, distinctUntilChanged, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  IStravaActivity,
  IStravaAthlete,
  IStravaRoute,
  SPORT_TYPE_ICON_MAPPER,
  StravaSportType,
} from '../../interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, combineLatest, of, throwError } from 'rxjs';
import { formatLength } from '@maplix/utils';
import * as moment from 'moment';
import { FormControl } from '@maplix/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'maplix-strava-activities-modal',
  templateUrl: './strava-activities-modal.component.html',
  styleUrls: ['./strava-activities-modal.component.scss'],
})
export class StravaActivitiesModalComponent extends DestroyComponent implements OnInit {
  public athlete$: Observable<IStravaAthlete | undefined> = this.stravaService.athlete$;

  public loadingAthlete$ = this.stravaService.loading$;
  public loadingListItems: boolean = false;

  public listItems$: Observable<IStravaActivity[] | IStravaRoute[] | undefined>;

  private fetchNewPage: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public page: number = 1;

  public pageSize: number = 30;

  public searchTerm: string;

  public selectedFilterControl: FormControl<string> = new FormControl('activity');

  public selectedFilterItems = [
    {
      value: 'activity',
      label: this.translate.instant('map.strava.routeSelector.MY', {
        filter: this.translate.instant('map.strava.routeSelector.ACTIVITIES'),
      }),
      icon: 'mdi mdi-run',
    },
    {
      value: 'route',
      label: this.translate.instant('map.strava.routeSelector.MY', {
        filter: this.translate.instant('map.strava.routeSelector.ROUTES'),
      }),
      icon: 'remixicon-route-line',
    },
  ];

  public get title(): string {
    return this.translate.instant('map.strava.routeSelector.TITLE', {
      filter: this.translate.instant(`map.strava.routeSelector.${this.selectedFilterControl.value?.toUpperCase()}`),
    });
  }

  public get notFoundTitle(): string {
    return this.translate.instant('map.strava.routeSelector.NOTFOUNDTITLE', {
      filter: this.translate.instant(`map.strava.routeSelector.${this.selectedFilterControl.value?.toUpperCase()}`),
    });
  }

  public get notFoundInfo(): string {
    return this.translate.instant('map.strava.routeSelector.NOTFOUNDINFO', {
      filter: this.translate.instant(`map.strava.routeSelector.${this.selectedFilterControl.value?.toUpperCase()}`),
    });
  }

  public authError$: Observable<string> = this.stravaService.authError$;

  public get maxActivities() {
    return this.stravaService.maxActivities;
  }

  public get maxRoutes() {
    return this.stravaService.maxRoutes;
  }

  constructor(private stravaService: StravaService, public modal: NgbActiveModal, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.listItems$ = combineLatest([
      this.stravaService.athlete$,
      this.stravaService.authConfig$,
      this.selectedFilterControl.value$,
      this.fetchNewPage,
    ]).pipe(
      filter(([, , , fetchNewPage]) => fetchNewPage !== false),
      distinctUntilChanged(
        ([prevAthlete, prevConfig, prevFilter], [currentAthlete, currentConfig, currentFilter, currentReload]) =>
          prevAthlete?.id === currentAthlete?.id &&
          prevConfig?.accessToken === currentConfig?.accessToken &&
          prevFilter === currentFilter &&
          !currentReload
      ),
      filter(([athlete, config]) => Boolean(athlete && config)),
      switchMap(([, , selectedFilter, fetchNewPage]) => {
        this.loadingListItems = true;

        if (selectedFilter === 'activity') {
          if ((!fetchNewPage && this.stravaService.getActivityCache()?.length) || this.stravaService.maxActivities) {
            return this.stravaService.activitiesCache$;
          }

          return this.stravaService.getActivities(this.page, this.pageSize).pipe(
            switchMap((activities) => {
              if (!activities?.length) {
                this.stravaService.maxActivities = true;
              }
              this.stravaService.addActivitiesToCache(activities);
              this.fetchNewPage.next(null);

              return this.stravaService.activitiesCache$;
            })
          );
        } else if (selectedFilter === 'route') {
          if ((!fetchNewPage && this.stravaService.getRouteCache()?.length) || this.stravaService.maxRoutes) {
            return this.stravaService.routeCache$;
          }

          return this.stravaService.getRoutes(this.page, this.pageSize).pipe(
            switchMap((routes) => {
              if (!routes?.length) {
                this.stravaService.maxRoutes = true;
              }
              this.stravaService.addRoutesToCache(routes);
              this.fetchNewPage.next(null);

              return this.stravaService.routeCache$;
            })
          );
        }
      }),
      tap(() => (this.loadingListItems = false)),
      catchError((err) => {
        this.loadingListItems = false;
        return throwError(err);
      }),
      takeUntil(this.destroyed)
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stravaService.onDestroyStravaModal();
  }

  public loadMoreItems() {
    this.page++;
    this.fetchNewPage.next(true);
  }

  public onDrawRoute() {
    this.modal.dismiss('DRAW');
  }

  public onSelectItem(id: string) {
    this.modal.close({ type: this.selectedFilterControl.value, id });
  }

  public mapActivityIcon(type: StravaSportType) {
    return SPORT_TYPE_ICON_MAPPER[type] ?? 'mdi mdi-dots-horizontal';
  }

  public formatDistance(distance) {
    return formatLength(distance, 10);
  }

  public formatTime(time) {
    if (time >= 24 * 60 * 60) {
      return `${Math.floor(time / (24 * 60 * 60))}d&nbsp;${Math.round(
        (time / (24 * 60 * 60) - Math.floor(time / (24 * 60 * 60))) * 24
      )}h`;
    }

    if (time >= 3600) {
      return `${Math.floor(time / 3600)}h &nbsp;${Math.round((time / 3600 - Math.floor(time / 3600)) * 60)}m`;
    }

    if (time >= 60) {
      return `${Math.floor(time / 60)}m&nbsp;${Math.round((time / 60 - Math.floor(time / 60)) * 60)}s`;
    }
  }

  public formatTempo({ distance, moving_time }) {
    const minPerKm = moving_time / (distance / 1000) / 60;
    const seconds = (minPerKm - Math.floor(minPerKm)) * 60;

    return `${Math.floor(minPerKm)}:${Math.round(seconds)}/km`;
  }

  public formatStats(activity, dFn, teFn, tiFn) {
    return `<div class="font-16 mb-1">
    ${dFn(activity.distance)} | ${tiFn(activity.moving_time)}
    </div>
    <div>${moment(activity.start_date_local).format('DD MMM yyyy HH:mm')}</div>`;
  }

  public retryStravaLogin() {
    this.stravaService.login();
  }
}
