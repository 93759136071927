import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@maplix/forms';
import { randomIdGenerator } from '@maplix/utils';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'maplix-slider-number',
  templateUrl: './slider-number.component.html',
  styleUrls: ['./slider-number.component.scss'],
})
export class SliderNumberComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  public min = 0;

  @Input()
  public max = 10;

  @Input()
  public step = 1;

  @Input()
  public suffix: string;

  @Input()
  public control: FormControl<number>;

  @Input()
  public fillValue: boolean;

  public sliderId: string;

  public isFirefox: boolean = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  constructor() {}

  ngOnInit() {
    this.sliderId = randomIdGenerator();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.control.value$
        .pipe(
          tap(() => {
            this.setBubble();
          })
        )
        .subscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.min || changes.max || changes.model) {
      this.setBubble();
    }
  }

  private setBubble() {
    const bubble = document.querySelector(`#bubble-${this.sliderId}`) as HTMLElement;
    if (bubble) {
      bubble.innerHTML = `${this.control.value}`;
      const pct = ((+this.control.value - this.min) * 100) / (this.max - this.min);
      const cssString = `calc(${pct}% + (${10 - pct * 0.2}px))`;
      bubble.style.left = cssString;
    }

    const progress = document.querySelector(`#slider-progress-${this.sliderId}`) as HTMLElement;
    if (progress) {
      const pct = ((+this.control.value - this.min) * 100) / (this.max - this.min);
      const cssString = `calc(${pct}% - ${pct * 0.2}px + 1px)`;
      progress.style.width = cssString;
    }
  }
}
