import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@maplix/forms';
import { DestroyComponent } from '@maplix/ng-utils';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'maplix-searchable-list',
  templateUrl: './searchable-list.component.html',
  styleUrls: ['./searchable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchableListComponent extends DestroyComponent implements OnInit, OnDestroy {
  public searchControl: FormControl<string> = new FormControl();

  @Input()
  public loading: boolean | null;

  @Input()
  public searchable: boolean = true;

  @Input()
  public paginationType: 'page' | 'scroll' | 'none' | undefined = 'page';

  @Input()
  public showIndicator: boolean = true;

  @Input()
  public page: number = 1;

  @Input()
  public pageSize: number = 20;

  @Input()
  public scrollPagination: boolean = false;

  @Input()
  public loadedItems: number = 0;

  @Input()
  public totalItems: number = 0;

  @Input()
  public searchPlaceholder: string = 'Search';

  @Input()
  public loadMoreItemsLabel: string = 'Load more items';

  @Input()
  public noMoreLoadLabel: string = 'No more items found';

  @Output()
  private pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  private apply: EventEmitter<string> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(400),
        tap(() => {
          this.onApplyFilter();
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public onPageChange(page?: number): void {
    this.pageChange.emit(page);
  }

  public onClearSearch(): void {
    if (this.searchControl?.value) {
      this.searchControl.setValue(null);
    }
  }

  public onApplyFilter(): void {
    this.apply.emit(this.searchControl.value);
  }
}
