<div class="position-relative">
  <div (click)="detailSelected = null" class="pt-2">
    <div class="d-flex justify-content-between align-items-center modal-container mb-2">
      <maplix-selector
        [items]="[
          {
            value: 'datasets',
            label: 'Datasets',
            icon: 'remixicon-server-line'
          },
          {
            value: 'surveys',
            label: 'Survey results',
            icon: 'remixicon-file-list-2-line'
          }
        ]"
        [control]="selectedFilterControl"
      ></maplix-selector>

      <div class="d-flex align-items-center">
        <div ngbDropdown placement="bottom-right" container=".modal-container">
          <maplix-icon-text-button
            [buttonType]="'primary'"
            [buttonText]="'New dataset'"
            [icon]="'remixicon-add-circle-fill'"
            ngbDropdownToggle
          ></maplix-icon-text-button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="onCreateNewDataset('WMS')">WMS (Web Map Service)</button>
            <button ngbDropdownItem (click)="onCreateNewDataset('UPLOAD')">Upload dataset</button>
            <!-- <button ngbDropdownItem (click)="onCreateNewDataset('DRAW')">
                Blank dataset
              </button> -->
          </div>
        </div>

        <maplix-grid-list-toggle [(selectedView)]="selectedView"></maplix-grid-list-toggle>
      </div>
    </div>

    <ng-container *ngIf="selectedView === 'grid'">
      <ng-container *ngIf="selectedFilterControl.value === 'datasets'">
        <maplix-searchable-list
          [searchable]="true"
          (apply)="onFilterDatasets($event)"
          [searchPlaceholder]="'Search datasets'"
          [page]="page"
          [pageSize]="pageSize"
          (pageChange)="getNewPage($event)"
          [totalItems]="totalItems"
          [loading]="loading"
        >
          <div class="d-flex flex-column align-items-center py-2" *ngIf="!loading && !dataSets?.length">
            <i class="remixicon-server-fill text-light" style="font-size: 80px"></i>
            <div class="h4">No datasets found</div>
            <p>Create a new dataset to add to your map.</p>
          </div>
          <ng-container *ngFor="let group of dataGroups">
            <ng-container *ngIf="dataSets | map: getDatasetsForGroup:group as groupedDatasets">
              <ng-container *ngIf="groupedDatasets?.length">
                <label class="form-label mb-2 text-muted ml-1">{{ group.label }}</label>
                <div class="row no-gutters">
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 px-1 pb-1" *ngFor="let dataset of groupedDatasets">
                    <maplix-dataset-card
                      [dataset]="dataset"
                      [isSelected]="selectedDataset?._id === dataset?._id"
                      (onSelect)="onSelectDataset(dataset)"
                      (onInfo)="onShowDetails(dataset)"
                    ></maplix-dataset-card>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </maplix-searchable-list>
      </ng-container>

      <ng-container *ngIf="selectedFilterControl.value === 'surveys'">
        <maplix-searchable-list
          [searchable]="true"
          (apply)="onFilterDatasets($event)"
          [searchPlaceholder]="'Search surveys'"
          [page]="page"
          [pageSize]="pageSize"
          (pageChange)="getNewPage($event)"
          [totalItems]="totalItems"
          [loading]="loading"
        >
          <div class="d-flex flex-column align-items-center py-2" *ngIf="!loading && !surveys?.length">
            <i class="remixicon-file-list-2-fill text-light" style="font-size: 80px"></i>
            <div class="h4">No surveys found</div>
            <p>Create and publish a survey to add the results to your map.</p>
          </div>
          <ng-container *ngFor="let group of dataGroups">
            <ng-container *ngIf="surveys | map: getDatasetsForGroup:group as groupedSurveys">
              <ng-container *ngIf="groupedSurveys?.length">
                <label class="form-label mb-2 text-muted ml-1">{{ group.label }}</label>
                <div class="row no-gutters">
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 px-1 pb-1" *ngFor="let survey of groupedSurveys">
                    <maplix-dataset-card
                      [survey]="survey"
                      [isSelected]="selectedSurvey?._id === survey?._id"
                      (onSelect)="onSelectSurvey(survey)"
                    ></maplix-dataset-card>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </maplix-searchable-list>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedView === 'list'">
      <ng-container *ngIf="selectedFilterControl.value === 'datasets'">
        <maplix-searchable-list
          [searchable]="true"
          (apply)="onFilterDatasets($event)"
          [searchPlaceholder]="'Search datasets'"
          [page]="page"
          [pageSize]="pageSize"
          (pageChange)="getNewPage($event)"
          [totalItems]="totalItems"
          [loading]="loading"
        >
          <div class="d-flex flex-column align-items-center py-2" *ngIf="!loading && !dataSets?.length">
            <i class="remixicon-server-fill text-light" style="font-size: 80px"></i>
            <div class="h4">No datasets found</div>
            <p>Create a new dataset to add to your map.</p>
          </div>
          <div class="table-responsive" *ngIf="dataSets?.length">
            <table class="table table-hover">
              <thead class="small">
                <th>Dataset</th>
                <th>Last modified</th>
                <th></th>
              </thead>
              <tbody>
                <tr *ngFor="let dataset of dataSets" [class.clickable]="dataset?.status !== 'PENDING'">
                  <td clas="w-100" (click)="dataset?.status !== 'PENDING' ? onSelectDataset(dataset) : null">
                    <div class="d-flex">
                      <i
                        [class]="
                          (dataset?.type === 'UPLOAD'
                            ? 'remixicon-folder-upload-fill'
                            : dataset?.type === 'WMS'
                            ? 'remixicon-server-fill'
                            : 'remixicon-map-2-line') + ' text-muted font-24 mr-2'
                        "
                      ></i>
                      <div>
                        <div class="d-flex align-items-center">
                          <div class="font-10 text-primary text-uppercase">
                            {{ dataset?.type === 'UPLOAD' ? 'Upload' : dataset?.type === 'WMS' ? 'WMS' : 'Dataset' }}
                          </div>
                          <div class="badge badge-light-warning ml-1" *ngIf="dataset?.status === 'PENDING'">
                            Processing
                          </div>
                        </div>
                        <div class="font-weight-600">
                          {{ dataset?.name }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td (click)="dataset?.status !== 'PENDING' ? onSelectDataset(dataset) : null">
                    {{ dataset?._updated | date: 'MMM dd, yyyy' }}
                  </td>
                  <td class="d-flex justify-content-end">
                    <button
                      class="btn btn-xs btn-light icon-btn"
                      (click)="onShowDetails(dataset)"
                      ngbTooltip="Details"
                      placement="left"
                    >
                      <i class="fas fa-info-circle"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </maplix-searchable-list>
      </ng-container>

      <ng-container *ngIf="selectedFilterControl.value === 'surveys'">
        <maplix-searchable-list
          [searchable]="true"
          [searchPlaceholder]="'Search surveys'"
          (apply)="onFilterDatasets($event)"
          [page]="page"
          [pageSize]="pageSize"
          (pageChange)="getNewPage($event)"
          [totalItems]="totalItems"
          [loading]="loading"
        >
          <div class="d-flex flex-column align-items-center py-2" *ngIf="!loading && !surveys?.length">
            <i class="remixicon-file-list-2-fill text-light" style="font-size: 80px"></i>
            <div class="h4">No surveys found</div>
            <p>Create and publish a survey to add the results to your map.</p>
          </div>
          <div class="table-responsive" *ngIf="surveys?.length">
            <table class="table table-hover">
              <thead class="small">
                <th>Survey</th>
                <th>Last modified</th>
              </thead>
              <tbody>
                <tr *ngFor="let survey of surveys" class="clickable">
                  <td (click)="onSelectSurvey(survey)">
                    <div class="d-flex">
                      <i class="remixicon-file-list-2-fill text-muted font-24 mr-2"></i>
                      <div>
                        <div class="font-10 text-primary text-uppercase">Survey</div>
                        <div class="font-weight-600">
                          {{ survey | map: getDefaultSurveyTitle }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td (click)="onSelectSurvey(survey)">
                    {{ survey?._updated | date: 'MMM dd, yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </maplix-searchable-list>
      </ng-container>
    </ng-container>
  </div>

  <div class="info-view pl-3 pr-2 border-left" *ngIf="detailSelected">
    <div class="stick-to-top">
      <maplix-dataset-detail
        [detailSelected]="detailSelected"
        (onDelete)="onDeleteDataset(detailSelected)"
        (onClose)="detailSelected = null"
        (copyUrl)="onCopyToClipboard($event)"
      ></maplix-dataset-detail>
    </div>
  </div>
</div>
