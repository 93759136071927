import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedService } from './shared/services/shared.service';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { LayoutsModule } from './layouts/layouts.module';
import { CookieService } from 'ngx-cookie-service';
import { UiModule } from '@maplix/ui';
import { CacheInterceptor, CleanObjectInterceptor } from '@maplix/utils';
import { DirectivesModule } from '@maplix/directives';
import { environment } from '../environments/environment';
import { ServicesModule } from '@maplix/services';
import { ApiModule } from '@maplix/api';
import { StravaPluginModule } from '@maplix/strava-plugin';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    AppRoutingModule,
    LayoutsModule,
    UiModule,
    DirectivesModule.forRoot(environment),
    ServicesModule.forRoot(environment),
    ApiModule.forRoot(environment),
    StravaPluginModule.forRoot(environment),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CleanObjectInterceptor, multi: true },
    SharedService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
