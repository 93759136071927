<div id="analyticsMap" class="analytics-map">
  <div class="loader-overlay d-flex flex-column align-items-center justify-content-center" *ngIf="loading">
    <div class="spinner-grow avatar-sm text-white" role="status"></div>
    <div class="h4 mt-3 text-white">Loading</div>
  </div>

  <div class="layer-editor border-right" *ngIf="showLayerEditor" [ngStyle]="{ width: editorWidth + 'px' }">
    <ng-content></ng-content>
  </div>

  <maplix-address-search [map]="map?.map" *ngIf="mapType === 'MAP'" [position]="'right'"> </maplix-address-search>

  <div style="visibility: hidden; position: absolute; top: 0; left: 0">
    <maplix-map-popup [popupInfo]="popupInfo" [mapId]="'analyticsMap'"></maplix-map-popup>
  </div>

  <maplix-compare-slider *ngIf="isPreview"></maplix-compare-slider>
</div>
