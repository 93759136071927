<div class="d-flex flex-wrap" [class.flex-column]="stacked">
  <button
    class="btn selector-btn mb-1 rating"
    *ngFor="let item of items; last as isLast; index as index"
    [class.btn-sm]="size !== 'lg'"
    [class.btn-lg]="size === 'lg'"
    [class.selected]="isSelected(item)"
    [disabled]="disabled || (item.value | map: isItemDisabled:disabledItems)"
    [class.btn-primary]="isSelected(item) || isHovered(index) || (isBeforeSelected(index) && !isAfterHovered(index))"
    [class.btn-light]="!isSelected(item) && !isHovered(index) && !(isBeforeSelected(index) && !isAfterHovered(index))"
    [class.mr-1]="!stacked && !isLast"
    [class.mb-2]="size === 'lg'"
    [ngStyle]="{ 'min-width': minWidthCss }"
    (click)="onSelectItem(item, index)"
    (mouseenter)="onHoverItem(index)"
    (mouseleave)="onStopHovering()"
  >
    <div class="d-flex align-items-center">
      <div
        class="mr-2"
        *ngIf="item.icon"
        [class.font-16]="!item.iconSize || item.iconSize === 'md'"
        [class.font-12]="item.iconSize === 'sm'"
        [class.font-20]="item.iconSize === 'lg'"
      >
        <div [class]="item.iconWrapper ? item.iconWrapper : ''">
          <i [class]="item.icon"></i>
        </div>
      </div>
      <div>
        <div>{{ item | map: getItemLabel:bindLabel }}</div>
      </div>
    </div>
  </button>
</div>
