<div class="quill-wrapper" [class.show-collapse-toggle]="collapsable">
  <quill-editor
    [id]="randomId"
    [modules]="editorModules"
    [formControl]="control"
    [placeholder]="placeholder"
    class="w-100"
    #quillEditor
  ></quill-editor>

  <div
    class="collapse-toggle clickable"
    [class.bg-soft-primary]="!collapsed"
    *ngIf="collapsable"
    [ngbTooltip]="collapsed ? 'Show format options' : 'Hide format options'"
    placement="left"
    (click)="onToggleCollapse()"
  >
    <i class="mdi mdi-format-letter-case-upper font-20 text-dark"></i>
  </div>
</div>

<ng-template #colorPicker let-modal>
  <div class="d-flex justify-content-center align-items-center">
    <maplix-color-dropdown
      [color]="'#304351'"
      [hideOpacity]="true"
      (colorChange)="onChangeColor($event)"
    ></maplix-color-dropdown>
  </div>
</ng-template>
