import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Realm from 'realm-web';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { WorkspaceService } from '@maplix/services';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RealmService {
  private app: Realm.App;

  constructor(private cookie: CookieService, private workspaceService: WorkspaceService) {
    this.app = new Realm.App({ id: environment.stitchId });
  }

  public auth = {
    checkApiKey: async (token): Promise<void> => {
      const credentials = Realm.Credentials.apiKey(token);
      await this.app.logIn(credentials).catch(() => {
        this.auth.logout();
      });
    },

    loginWithEmail: (email, password): Promise<Realm.User> => {
      const credentials = Realm.Credentials.emailPassword(email, password);
      return this.app.logIn(credentials);
    },

    loginWithGoogle: async (): Promise<Realm.User> => {
      const credentials = Realm.Credentials.google(`${environment.account}auth/redirect`);
      const user: Realm.User = await this.app.logIn(credentials);
      return user;
    },

    loginWithFacebook: async (redirectUrl: string): Promise<Realm.User> => {
      const credentials = Realm.Credentials.facebook(redirectUrl);
      const user: Realm.User = await this.app.logIn(credentials);
      return user;
    },

    registerWithEmail: (email, password): Promise<void> => {
      return this.app.emailPasswordAuth.registerUser(email, password);
    },

    resetPasswordWithEmail: (email): Promise<void> => {
      return this.app.emailPasswordAuth.callResetPasswordFunction(email, 'temporary', []);
    },

    resetPasswordConfirm: (token, tokenId, password): Promise<void> => {
      return this.app.emailPasswordAuth.resetPassword(token, tokenId, password);
    },

    registerWithEmailConfirm: (token: string, tokenid: string): Promise<void> => {
      return this.app.emailPasswordAuth.confirmUser(token, tokenid);
    },

    setApiKey: async (): Promise<void> => {
      const user: Realm.User = this.app.currentUser;
      const currentToken: string = this.cookie.get(`MaplixToken${environment.env}`);
      if (!currentToken) {
        const keys = await user.apiKeys.fetchAll();
        if (keys.length >= 10) {
          keys.sort((a, b) => {
            if (moment(a.name, 'DDMMYYYYHHmmss').diff(moment(b.name, 'DDMMYYYYHHmmss')) < 0) {
              return -1;
            } else if (moment(a.name, 'DDMMYYYYHHmmss').diff(moment(b.name, 'DDMMYYYYHHmmss')) === 0) {
              return -1;
            } else {
              return 1;
            }
          });
          await user.apiKeys.delete(keys[0]._id);
        }
        const apiKey = await user.apiKeys.create(moment().format('DDMMYYYYHHmmss'));
        this.cookie.set(`MaplixToken${environment.env}`, apiKey.key, 30, '/', environment.cookieDomain);
        this.cookie.set(`MaplixTokenId${environment.env}`, apiKey._id, 30, '/', environment.cookieDomain);
      }
    },

    updateCustomData: async (): Promise<void> => {
      await this.app.currentUser.refreshCustomData();
      const details = cloneDeep(this.app.currentUser) as any;
      details.environment = environment.env;
      this.cookie.set(`userDetails${environment.env}`, JSON.stringify(details), 30, '/', environment.cookieDomain);
      this.workspaceService.setUserDetails(details);
    },

    removeApiKey: async (token): Promise<void> => {
      const credentials = Realm.Credentials.apiKey(token);
      const user: Realm.User = await this.app.logIn(credentials);
      const tokenId = this.cookie.get(`MaplixTokenId${environment.env}`);
      if (tokenId) {
        await user.apiKeys.delete(tokenId);
      }
    },

    logout: () => {
      this.cookie.delete(`userDetails${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`activeWorkspace${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`MaplixToken${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`MaplixTokenId${environment.env}`, '/', environment.cookieDomain);
      this.workspaceService.setUserDetails(undefined);
      this.cookie.set(`redirectAfterLogin${environment.env}`, environment.engage, null, '/', environment.cookieDomain);
      location.href = `${environment.account}auth/login`;
    },

    resendConfirmEmail: (email: string) => {
      return this.app.emailPasswordAuth.retryCustomConfirmation({ email });
    },
  };
}
