import { Component } from '@angular/core';
import { WorkspaceService } from '@maplix/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'account-schedule-demo-modal',
  templateUrl: './schedule-demo-modal.component.html',
  styleUrls: ['./schedule-demo-modal.component.scss'],
})
export class ScheduleDemoModalComponent {
  public user$ = this.workspaceService.userDetails$;

  constructor(public modal: NgbActiveModal, private workspaceService: WorkspaceService) {}

  public getFirstName(user: string) {
    if (!user) {
      return;
    }

    return user.split(' ')[0];
  }
}
