<a
  href="https://www.maplix.com"
  target="_blank"
  class="maplix-logo align-items-center small text-muted"
  [class.d-flex]="!hideOnSmartphone"
  [class.d-none]="hideOnSmartphone"
  [class.d-md-flex]="hideOnSmartphone"
>
  <img [src]="'assets/images/' + (app === 'ENGAGE' ? 'engage' : 'explore') + '_logo_primary.svg'" class="mr-1" />
  <span class="mr-1">&copy;</span>
  Powered by Maplix
</a>
