export interface IBaseLayerPlaceholder {
  id: string;
  label: string;
  description: string;
}

export const BASELAYERS: IBaseLayerPlaceholder[] = [
  {
    id: 'mapbox-basic',
    label: 'Basic',
    description:
      'Subtle basemap displaying only the most relevant features for orientation on the map in bright colors, focusing on recognizability for a broad audience.',
  },
  {
    id: 'mapbox-streets',
    label: 'Streets',
    description:
      'Detailed basemap emphasizing road and transit networks of a wide variety of transport modes using bright colors, ensuring a quick understanding of road hierarchies and classifications.',
  },
  {
    id: 'mapbox-outdoors',
    label: 'Outdoors',
    description:
      'Detailed basemap displaying natural and topographical features while emphasizing hiking and biking networks, providing a complete context for adventurous data to be displayed and understood.',
  },
  {
    id: 'mapbox-spring',
    label: 'Spring',
    description:
      'Subtle basemap displaying only the most relevant features for orientation on the map in soft colors while emphasizing walking and biking networks, facilitating full focus on walking and cycling data on the map.',
  },
  {
    id: 'mapbox-light',
    label: 'Light',
    description:
      'Subtle basemap displaying all features on the map in different shades of light grey and white, ensuring no distraction from your own data visualizations.',
  },
  {
    id: 'mapbox-dark',
    label: 'Dark',
    description:
      'Subtle basemap displaying all features on the map in different shades of light grey and white, ensuring no distraction from your own data visualizations.',
  },
  {
    id: 'mapbox-satellite',
    label: 'Satellite',
    description:
      'Satellite	imagery basemap displaying satellite and aerial images of the terrain, supplemented by a subtle layer of road and transit networks to facilitate easy orientation on the map.',
  },
];
