export const environment = {
  env: 'PROD',
  cookieDomain: 'maplix.com',
  production: true,
  root: 'https://account.maplix.com/',
  engage: 'https://engage.maplix.com/',
  account: 'https://account.maplix.com/',
  explore: 'https://explore.maplix.com/',
  api: `https://api.maplix.com/`,
  stitchId: 'maplix-account-prod-ukpqz',
  plausible: {
    domain: 'account.maplix.com',
  },
};
