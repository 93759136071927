import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl as SafeFormControl,
  ValidatorFn,
} from 'ngx-typesafe-forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractControl } from './abstract-control';
import { FormState } from './interfaces';

export class FormControl<T>
  extends SafeFormControl<T>
  implements AbstractControl<T> {
  private validators: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public validators$: Observable<string[]> = this.validators.asObservable();

  private asyncValidators: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public asyncValidators$: Observable<
    string[]
  > = this.asyncValidators.asObservable();

  /**
   * Creates a new `FormControl` instance.
   *
   * @param formState Initializes the control with an initial value,
   * or an object that defines the initial value and disabled state.
   *
   * @param validatorOrOpts An dictionary synchronous validator function, or an array of
   * such functions, or an `AbstractControlOptions` object that contains validation functions
   * and a validation trigger.
   *
   * @param asyncValidator A single async validator or array of async validator functions
   *
   */
  constructor(
    formState?: T | FormState<T>,
    validatorOrOpts?:
      | ValidatorFn<T>
      | ValidatorFn<T>[]
      | AbstractControlOptions<T>
      | null,
    asyncValidator?: AsyncValidatorFn<T> | AsyncValidatorFn<T>[] | null
  ) {
    // Bug in ngx-typesafe forms that cannot handle a formState object in the constructor so we need to handle it ourselves
    super(
      formState &&
        typeof formState === 'object' &&
        !Array.isArray(formState) &&
        'value' in formState
        ? formState.value
        : (formState as T),
        validatorOrOpts,
        asyncValidator
    );

    if (
      formState &&
      this.isFormState(formState) &&
      (formState as FormState<T>).disabled
    ) {
      this.disable();
    }

    if (validatorOrOpts) {
      if (this.isAbstractControlOption(validatorOrOpts)) {
        if (
          Array.isArray(
            (validatorOrOpts as AbstractControlOptions<T>).validators
          )
        ) {
          this.validators.next([
            ...((validatorOrOpts as AbstractControlOptions<T>)
              .validators as ValidatorFn<T>[]).map((v) => v.name),
          ]);
        } else {
          this.validators.next([
            ((validatorOrOpts as AbstractControlOptions<T>)
              .validators as ValidatorFn<T>).name,
          ]);
        }
        this.setValidators(
          (validatorOrOpts as AbstractControlOptions<T>).validators
        );

        if (
          Array.isArray(
            (validatorOrOpts as AbstractControlOptions<T>).asyncValidators
          )
        ) {
          this.asyncValidators.next([
            ...((validatorOrOpts as AbstractControlOptions<T>)
              .asyncValidators as AsyncValidatorFn<T>[]).map((v) => v.name),
          ]);
        } else {
          this.asyncValidators.next([
            ((validatorOrOpts as AbstractControlOptions<T>)
              .asyncValidators as AsyncValidatorFn<T>).name,
          ]);
        }
        this.setAsyncValidators(
          (validatorOrOpts as AbstractControlOptions<T>).asyncValidators
        );
      } else if (Array.isArray(validatorOrOpts)) {
        this.validators.next(validatorOrOpts.map((v) => v.name));
        this.setValidators(validatorOrOpts);
      } else {
        this.validators.next([(validatorOrOpts as ValidatorFn<T>).name]);
        this.setValidators(validatorOrOpts as ValidatorFn<T>);
      }
    }

    if (asyncValidator) {
      this.asyncValidators.next(Object.keys(asyncValidator));
      this.setAsyncValidators(Object.values(asyncValidator));
    }
  }

  private isFormState(formState: T | FormState<T>): boolean {
    return (
      typeof formState === 'object' &&
      !Array.isArray(formState) &&
      'value' in formState
    );
  }

  private isAbstractControlOption(
    validatorOrOpts:
      | ValidatorFn<T>
      | ValidatorFn<T>[]
      | AbstractControlOptions<T>
  ): boolean {
    return 'validators' in validatorOrOpts;
  }

  /**
   * Returns an array of the names of all the validators on the control
   */
  public getValidators(): string[] {
    return this.validators.value;
  }

  /**
   * Returns an array of the names of all the async validators on the control
   */
  public getAsyncValidators(): string[] {
    return this.validators.value;
  }

  /**
   * Returns a boolean whether or not the control contains a validator
   *
   * @param key The name of the validator function
   */
  public hasValidatorByKey(key: string): boolean {
    return this.validators.value && this.validators.value.includes(key);
  }

  /**
   * Returns a boolean whether or not the control contains an async validator
   *
   * @param key The name of the validator function
   */
  public hasAsyncValidatorByKey(key: string): boolean {
    return (
      this.asyncValidators.value && this.asyncValidators.value.includes(key)
    );
  }

  /**
   * Sets the synchronous validators that are active on this control.  Calling
   * this overwrites any existing sync validators.
   *
   * When you add or remove a validator at run time, you must call
   * `updateValueAndValidity()` for the new validation to take effect.
   *
   */
  public setValidators(
    newValidator: ValidatorFn<T> | ValidatorFn<T>[] | null
  ): void {
    if (!newValidator) {
      this.validators.next([]);
      return;
    }

    if (Array.isArray(newValidator)) {
      this.validators.next([...newValidator.map((v) => v.name)]);
      return;
    }

    this.validators.next([newValidator.name]);
  }

  /**
   * Sets the async validators that are active on this control. Calling this
   * overwrites any existing async validators.
   *
   * When you add or remove a validator at run time, you must call
   * `updateValueAndValidity()` for the new validation to take effect.
   *
   */
  setAsyncValidators(
    newValidator: AsyncValidatorFn<T> | AsyncValidatorFn<T>[] | null
  ): void {
    if (!newValidator) {
      this.asyncValidators.next([]);
      return;
    }

    if (Array.isArray(newValidator)) {
      this.asyncValidators.next([...newValidator.map((v) => v.name)]);
      return;
    }

    this.asyncValidators.next([newValidator.name]);
  }
}
