import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { ICountryCode, ToastrNotification } from '@maplix/utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '@maplix/api';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public notification: ToastrNotification;

  public countryCodes$: Observable<ICountryCode[]> = this.http.get<ICountryCode[]>('assets/country_codes.json').pipe(
    catchError((err) => {
      this.api.errorhandler(err);
      return throwError(err);
    })
  );

  constructor(private http: HttpClient, toastr: ToastrService, private api: ApiService) {
    this.notification = new ToastrNotification(toastr);
  }

  onGoTo(url: string) {
    location.href = environment[url];
  }
}
