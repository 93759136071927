import { Component } from '@angular/core';
import { PlausibleService } from '@maplix/services';
import { environment } from '../environments/environment';

@Component({
  selector: 'account-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public cookieDomain: string = environment.cookieDomain;

  constructor(private plausible: PlausibleService) {
    this.plausible.initTracker();
  }
}
