<div class="card-header">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div class="h4 my-0">
        {{ legendText }}
      </div>
      <ng-container *ngIf="helptext">
        <i class="remixicon-question-line text-muted ml-2 clickable" [ngbPopover]="helptext" placement="bottom" container="body"></i>
      </ng-container>
    </div>
    
    <i class="clickable remixicon-close-fill font-18" (click)="onCloseSidebar()" *ngIf="(close | hasObservers)"></i>
  </div>
</div>

<ng-container *ngIf="!editable">
  <maplix-map-layers [dataLayers]="mapForm.get('dataLayers')" [wmsLegends]="wmsLegends" [language]="language"
    [editable]="editable" (toggleVisibility)="onChangeVisibility($event)">
  </maplix-map-layers>
</ng-container>

<ng-container *ngIf="editable && mapForm">
  <maplix-map-layers (toggleVisibility)="onChangeVisibility($event)" [dataLayers]="mapForm.get('dataLayers')"
    (changeLayerOrder)="onChangeLayerOrder()" [wmsLegends]="wmsLegends" [language]="language"
    (open)="onOpenLayerModal($event)" (openCloud)="onOpenCloudModal()" [editable]="editable"
    (toggleHidden)="onToggleHidden($event)" (onDelete)="onDeleteLayer($event)">
  </maplix-map-layers>
</ng-container>