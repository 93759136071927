<header class="navbar-sticky nav-sticky bg-white" id="topnav">
  <nav class="navbar navbar-expand-md navbar-light p-0">
    <div class="container">
      <!-- LOGO -->
      <a routerLink="/" class="logo-container py-3 py-md-0">
        <span class="logo-lg d-flex align-items-start h-100">
          <div class="d-flex align-items-center my-auto">
            <img src="assets/images/maplix_logo_primary.svg" alt="" height="35" />
            <!-- <div class="h5 app-tag">
              <div class="badge badge-light-primary">Account</div>
            </div> -->
          </div>
        </span>
      </a>

      <button class="navbar-toggler ml-auto" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" *ngIf="showNav">
        &#9776;
      </button>

      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" *ngIf="showNav">
        <ul class="navbar-nav list-unstyled topnav-menu ml-auto align-items-center">
          <li class="nav-item">
            <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/workspace" routerLinkActive="active">Workspace</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/team" routerLinkActive="active">Team</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/profile" routerLinkActive="active">Account</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact">
              <button class="btn btn-primary">Contact&nbsp;sales</button>
            </a>
          </li>
          <li class="dropdown nav-item" ngbDropdown *ngIf="userDetails$ | async as userDetails">
            <a
              class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
              ngbDropdownToggle
              id="profileDropdown"
              href="javascript: void(0);"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                [src]="
                  userDetails?.image
                    ? userDetails?.image
                    : userDetails?.user_profile?.data?.picture
                    ? userDetails?.user_profile?.data?.picture
                    : 'assets/images/profile-empty.png'
                "
                alt=""
                class="img-border"
              />
              <span class="pro-user-name ml-1">
                <i class="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right profile-dropdown"
              aria-labelledby="profileDropdown"
              ngbDropdownMenu
            >
              <a [href]="engageUrl" class="dropdown-item">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="avatar-image mr-3">
                      <img src="assets/images/engage_mix.svg" alt="" />
                    </div>
                    <div>
                      <div class="mb-1">
                        <img src="assets/images/engage_logo_primary.svg" height="30" />
                      </div>
                      <small class="text-muted">Start creating your survey</small>
                    </div>
                  </div>
                  <i class="mdi mdi-chevron-right pl-4 font-20 text-muted"></i>
                </div>
              </a>
              <a [href]="exploreUrl" class="dropdown-item">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="avatar-image mr-3">
                      <img src="assets/images/explore_mix.svg" alt="" />
                    </div>
                    <div>
                      <div class="mb-1">
                        <img src="assets/images/explore_logo_primary.svg" height="30" />
                      </div>
                      <small class="text-muted">Start designing your map</small>
                    </div>
                  </div>
                  <i class="mdi mdi-chevron-right pl-4 font-20 text-muted"></i>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a routerLink="superuser" class="dropdown-item" *ngIf="isSuperUser$ | async">
                <div class="d-flex align-items-center">
                  <i class="remixicon-admin-line mr-2"></i>
                  <span>Maplix Superuser</span>
                </div>
              </a>
              <!-- <a routerLink="profile" class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="remixicon-user-line mr-2"></i>
                  <span>My account</span>
                </div>
              </a> -->
              <a href="javascript:void(0);" class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="remixicon-logout-box-line mr-2"></i>
                  <span>Sign out</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="">
    <hr class="bottom my-0 w-100" />
  </div>
</header>
