import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, ReverseAuthGuard, SuperuserGuard } from '@maplix/guards';
import { environment } from '../environments/environment';
import { LayoutComponent } from './layouts/layout.component';
import { StravaRedirectComponent } from '@maplix/strava-plugin';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [ReverseAuthGuard],
    data: { env: environment },
  },
  {
    path: 'superuser',
    loadChildren: () => import('./superuser/superuser.module').then((m) => m.SuperuserModule),
    canActivate: [SuperuserGuard],
    data: { env: environment },
  },
  {
    path: 'strava-redirect',
    component: StravaRedirectComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
    data: { env: environment },
  },
  {
    path: 'error',
    loadChildren: () => import('@maplix/error').then((m) => m.ErrorModule),
  },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
