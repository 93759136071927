<div
  [id]="fullscreenBox"
  class="d-flex fullscreen-preview bg-white"
  [class.flex-row-reverse]="activeMap?.options?.theme?.options?.map?.legend.position === 'LEFT'"
  [ngStyle]="{ height: height }"
>
  <div [id]="mapId" class="analytics-map" [class.no-base-toggle]="!showBaseToggle">
    <div class="loader-overlay d-flex flex-column align-items-center justify-content-center" *ngIf="loading">
      <div class="spinner-grow avatar-sm text-white" role="status"></div>
      <div class="h4 mt-3 text-white">Loading</div>
    </div>

    <maplix-sidebar-toggle
      [showText]="legendText"
      [(showSidebox)]="showSidebar"
      (sideboxChange)="onToggleSidebox()"
      [icon]="'fe-layers'"
      [position]="activeMap?.options?.theme?.options?.map?.legend.position"
      *ngIf="showSidebarToggle"
    ></maplix-sidebar-toggle>

    <maplix-address-search
      [map]="map?.map"
      *ngIf="mapType === 'MAP' && showAddressSearch"
      [spaceRight]="['RIGHT', 'TOP-RIGHT'].includes(activeMap?.options?.theme?.options?.map?.legend.position)"
      [placeholder]="searchPlaceholder"
    >
    </maplix-address-search>

    <maplix-baselayer-toggle
      [loading]="loading"
      *ngIf="mapType === 'MAP' && showBaseToggle"
      [maxHeight]="maxBaseToggleHeight"
      [(activeLayer)]="mapForm?.get('baseLayer').value"
      (activeLayerChange)="onChangeBaseLayer($event)"
      [tooltipText]="editable ? null : ('map.CHANGEBASELAYER' | translate)"
    >
    </maplix-baselayer-toggle>

    <maplix-map-attribution *ngIf="!editable" [app]="app"></maplix-map-attribution>

    <ng-content></ng-content>
  </div>

  <div
    class="card sidebar mb-0 perfect-scroll position-relative"
    [class.start-sidebar-hidden]="collapseByDefault"
    [id]="'sidebar' + mapId"
    *ngIf="editable || mapForm?.get('dataLayers').value?.length"
  >
    <maplix-map-sidebar
      (toggleVisibility)="onChangeVisibility($event)"
      [mapForm]="mapForm"
      [mapType]="mapType"
      (changeLayerOrder)="onChangeLayerOrder()"
      [wmsLegends]="WMSLegendUrls"
      [language]="language?.code"
      (open)="onOpenModal($event)"
      (openCloud)="onOpenCloudModal()"
      [editable]="editable"
      *ngIf="mapForm"
      (close)="onToggleSidebox()"
      [legendText]="legendText"
      [helptext]="helptext"
      (toggleHidden)="onChangeHidden($event)"
      (onDelete)="onDeleteLayer($event)"
    >
    </maplix-map-sidebar>
  </div>
</div>

<div style="visibility: hidden; position: absolute; top: 0; left: 0">
  <maplix-map-popup [popupInfo]="popupInfo" [mapId]="mapId"></maplix-map-popup>
</div>
