<div class="modal-body py-4">
  <div class="p-3 bg-secondary card border-0">
    <div class="d-flex justify-content-between">
      <div>
        <h2 class="my-0 text-white">Welcome aboard, {{ (user$ | async).name | map: getFirstName }}!</h2>
      </div>
      <div class="rocket-container">
        <img src="assets/images/rocket.svg" class="rocket-image" />
      </div>
    </div>
  </div>

  <p class="text-left">
    Have fun discovering our map-based digital participation tools <strong>Engage</strong> and <strong>Explore</strong>.
  </p>
  <p class="mb-3 text-left">
    Looking for <strong>additional expertise</strong> to turn your geo-participation projects into a success? Let's have
    a chat to discover how we can help each other out.
  </p>

  <div class="d-flex flex-column align-items-center">
    <button class="btn btn-primary" (click)="modal.close(true)">
      Schedule a demo
      <i class="fas fa-chevron-right ml-2 font-11"></i>
    </button>
  </div>
</div>

<div class="d-flex justify-content-center below-modal w-100">
  <a class="text-white text-underline clickable" (click)="modal.close(false)">Maybe later</a>
</div>
