import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { FileUploadService } from '../../services';
import { FileUploadHandler, IUploadResponse } from '@maplix/utils';
@Component({
  selector: 'maplix-icon-upload',
  templateUrl: './icon-upload.component.html',
  styleUrls: ['./icon-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconUploadComponent implements OnInit {
  @Input()
  public type: 'image' | 'pdf' = 'image';

  @Input()
  public files: string[] | SafeUrl[];

  @Input()
  public file: string | SafeUrl;

  @Input()
  public multiple: boolean;

  @Input()
  public size: 'small' | 'medium' | 'large' = 'small';

  @Input()
  private apiUrl: string;

  @Input()
  private maxSize: number = 1024 * 1024;

  @Input()
  public disabled: boolean;

  @Input()
  private skipCloud: boolean;

  @Input()
  public showRemove: boolean = true;

  @Output()
  public filesChange: EventEmitter<string[] | SafeUrl[]>;

  @Output()
  public fileChange: EventEmitter<string | SafeUrl>;

  @Output('onUpload')
  public uploadEvent: EventEmitter<IUploadResponse | IUploadResponse[]>;

  public selectedFiles: File[];

  private uploadResponses: IUploadResponse[];

  private uploadResponse: IUploadResponse;

  public error: string;

  public uploading: boolean;

  @ViewChild('filePicker', { static: false })
  public picker: ElementRef;

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
    private uploadService: FileUploadService
  ) {
    this.uploadEvent = new EventEmitter<IUploadResponse | IUploadResponse[]>();
    this.fileChange = new EventEmitter<string | SafeUrl>();
    this.filesChange = new EventEmitter<string[] | SafeUrl[]>();
  }

  ngOnInit() {}

  public async onOpenFileSelector() {
    if (this.skipCloud) {
      this.onOpenFilePicker();
      return;
    }

    let selectedFile = await this.uploadService.onOpenCloudModal();

    if (selectedFile === 'upload') {
      this.onOpenFilePicker();
    } else if (selectedFile) {
      this.file = selectedFile;
      this.fileChange.emit(this.file);
    }
  }

  public onOpenFilePicker() {
    this.picker.nativeElement.click();
  }

  public async onUploadFile(args): Promise<void> {
    if (!(args.target as HTMLInputElement).files.length) return;
    this.error = null;
    this.uploading = true;
    const newUploads: any = Array.from((args.target as HTMLInputElement).files);
    let doneUploading = 0;
    for (let upload of newUploads) {
      if (this.type === 'image' && !upload.type.includes('image/')) {
        this.error = 'Please upload a valid image file.';
        doneUploading++;
        if (doneUploading === newUploads.length) {
          this.uploading = false;
        }
        return;
      } else if (this.type === 'pdf' && upload.type !== 'application/pdf') {
        this.error = 'Please upload a valid pdf file.';
        doneUploading++;
        if (doneUploading === newUploads.length) {
          this.uploading = false;
        }
      }
      const handler = new FileUploadHandler({ file: upload, maxSize: this.maxSize, apiUrl: this.apiUrl }, this.http);
      handler
        .upload()
        .then((response) => {
          if (this.multiple) {
            this.files.push(response.url);
            this.uploadResponses.push(response);
            this.filesChange.emit(this.files);
            this.uploadEvent.emit(this.uploadResponses);
          } else {
            this.file = response.url;
            this.uploadResponse = response;
            this.fileChange.emit(this.file);
            this.uploadEvent.emit(this.uploadResponse);
          }

          doneUploading++;
          if (doneUploading === newUploads.length) {
            this.uploading = false;
          }
        })
        .catch((errorMsg) => {
          this.error = errorMsg;
          doneUploading++;
          if (doneUploading === newUploads.length) {
            this.uploading = false;
          }
        });
    }
  }

  public onRemoveFile(f?: string) {
    if (this.multiple) {
      this.files = this.files.filter((i) => i !== f);
      this.filesChange.next(this.files);
      return;
    }

    this.file = null;
    this.fileChange.next(null);
  }

  public onChangeFile() {
    this.onOpenFileSelector();
  }
}
