import { Inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Coordinate } from 'ol/coordinate';
import { map } from 'rxjs/operators';
import { first } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MapSettingsService {
  public mapboxToken;

  constructor(
    @Inject('environment') environment: any,
    private httpHandler: HttpBackend
  ) {
    this.mapboxToken = environment.mapboxToken;
  }

  public calculateRoute(
    coordinates: [Coordinate, Coordinate],
    profile: string
  ): Promise<any> {
    const hClient = new HttpClient(this.httpHandler);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return hClient
      .get(
        `https://api.mapbox.com/directions/v5/${profile}/${coordinates[0][0]},${coordinates[0][1]};${coordinates[1][0]},${coordinates[1][1]}?alternatives=false&geometries=geojson&steps=false&access_token=${this.mapboxToken}`,
        { headers }
      )
      .pipe(map((response: any) => first(response.routes)))
      .toPromise();
  }
}
