<div class="sidebar-toggle ol-control" [class.sidebar-toggle-left]="position === 'LEFT'" *ngIf="!showSidebox">
  <button
    class="btn btn-primary"
    (click)="onToggleSidebox()"
    ngbTooltip="{{ showText }}"
    [placement]="position === 'LEFT' ? 'right' : 'left'"
  >
    <ng-container *ngIf="icon">
      <i [class]="icon"></i>
    </ng-container>
  </button>
</div>
