<div id="slider-drag-boundary">
  <div class="position-relative h-100">
    <div
      id="slider"
      cdkDrag
      cdkDragLockAxis="x"
      cdkDragBoundary="#slider-drag-boundary"
      [cdkDragFreeDragPosition]="dragPosition"
      (cdkDragMoved)="onMoveSlider($event)"
    >
      <div id="slider-line"></div>
      <div id="slider-handle">
        <div class="d-flex align-items-center">
          <div class="slider-prepend">
            <label class="form-label">Current</label>
          </div>
          <div class="slider-circle bg-primary dragable row no-gutters">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <i class="remixicon-arrow-left-s-fill ml-1"></i>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <i class="remixicon-arrow-right-s-fill mr-1"></i>
            </div>
          </div>
          <div class="slider-append">
            <label class="form-label">Pending</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
