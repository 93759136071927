import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent, throwError } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { IStravaActivity, IStravaAthlete, IStravaRoute } from '../interfaces';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export interface IStravaAuthConfig {
  accessToken: string;
  refreshToken: string;
  expiresAt: moment.Moment;
}

@Injectable({
  providedIn: 'root',
})
export class StravaService {
  private stravaToken: string | null;

  private stravaScopes: string[] | undefined;

  public stravaChange$ = fromEvent<StorageEvent>(window, 'storage').pipe(
    filter((event) => event.key === 'strava-token')
  );

  private athlete: BehaviorSubject<IStravaAthlete | undefined> = new BehaviorSubject<IStravaAthlete | undefined>(
    undefined
  );
  public athlete$: Observable<IStravaAthlete | undefined> = this.athlete.asObservable();

  private destroyStravaModal: Subject<void> = new Subject();

  private http = new HttpClient(this.httpHandler);

  private authConfig: BehaviorSubject<IStravaAuthConfig | undefined> = new BehaviorSubject<
    IStravaAuthConfig | undefined
  >(undefined);
  public authConfig$: Observable<IStravaAuthConfig | undefined> = this.authConfig.asObservable();

  private authError: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public authError$: Observable<string> = this.authError.asObservable();

  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loading.asObservable();

  private activitiesCache: BehaviorSubject<IStravaActivity[]> = new BehaviorSubject([]);
  public activitiesCache$: Observable<IStravaActivity[]> = this.activitiesCache.asObservable();

  private routeCache: BehaviorSubject<IStravaRoute[]> = new BehaviorSubject([]);
  public routeCache$: Observable<IStravaRoute[]> = this.routeCache.asObservable();

  public maxActivities: boolean = false;
  public maxRoutes: boolean = false;

  constructor(
    @Inject('environment') private environment: any,
    private httpHandler: HttpBackend,
    private translate: TranslateService
  ) {}

  public addAuthListener() {
    this.stravaChange$
      .pipe(
        tap((e: StorageEvent) => {
          if (e.newValue && !e.newValue.includes('error')) {
            this.stravaToken = JSON.parse(e.newValue).token;
            this.stravaScopes = JSON.parse(e.newValue).scope?.split(',');
          } else {
            this.stravaToken = null;
            this.stravaScopes = [];
          }

          if (
            this.stravaToken &&
            (this.stravaScopes?.includes('activity:read') || this.stravaScopes?.includes('activity:read_all'))
          ) {
            this.http
              .post<any>(`https://www.strava.com/oauth/token`, {
                client_id: this.environment.strava.clientId,
                client_secret: this.environment.strava.clientSecret,
                code: this.stravaToken,
                grant_type: 'authorization_code',
              })
              .pipe(
                tap((response) => {
                  this.athlete.next(response.athlete);
                  this.authConfig.next({
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                    expiresAt: moment().add(response.expires_in, 'seconds'),
                  });
                  this.loading.next(false);
                }),
                catchError((err) => {
                  console.error(err);
                  this.loading.next(false);
                  return throwError(err);
                }),
                takeUntil(this.destroyStravaModal)
              )
              .subscribe();
          } else if (this.stravaToken) {
            this.authError.next(this.translate.instant('map.strava.error.ACTIVITYSCOPE'));
            this.loading.next(false);
          } else {
            this.authError.next(this.translate.instant('map.strava.error.CANCELAUTH'));
            this.loading.next(false);
          }
        }),
        takeUntil(this.destroyStravaModal)
      )
      .subscribe();
  }

  public login() {
    this.loading.next(true);
    this.authError.next(null);

    window.open(
      `http://www.strava.com/oauth/authorize?client_id=${this.environment.strava.clientId}&response_type=code&redirect_uri=${this.environment.root}/strava-redirect&approval_prompt=force&scope=read,read_all,activity:read,activity:read_all`
    );
  }

  public onDestroyStravaModal() {
    this.destroyStravaModal.next();
  }

  public getAuthConfig() {
    return this.authConfig.value;
  }

  public getActivities(page: number = 1, pageSize: number = 25) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authConfig.value?.accessToken}`);

    return this.http
      .get<IStravaActivity[]>(`https://www.strava.com/api/v3/athlete/activities?page=${page}&per_page=${pageSize}`, {
        headers,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public getActivityById(id: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authConfig.value?.accessToken}`);

    return this.http
      .get<IStravaActivity>(`https://www.strava.com/api/v3/activities/${id}`, {
        headers,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public getRoutes(page: number = 1, pageSize: number = 25) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authConfig.value?.accessToken}`);

    return this.http
      .get<IStravaRoute[]>(
        `https://www.strava.com/api/v3/athletes/${this.athlete.value?.id}/routes?page=${page}&per_page=${pageSize}`,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public getRouteById(id: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authConfig.value?.accessToken}`);

    return this.http
      .get<IStravaRoute>(`https://www.strava.com/api/v3/routes/${id}`, {
        headers,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public addActivitiesToCache(activities: IStravaActivity[]) {
    this.activitiesCache.next([...this.activitiesCache.value, ...activities]);
  }

  public getActivityCache() {
    return this.activitiesCache.value;
  }

  public addRoutesToCache(routes: IStravaRoute[]) {
    this.routeCache.next([...this.routeCache.value, ...routes]);
  }

  public getRouteCache() {
    return this.routeCache.value;
  }
}
