import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { WorkspaceService } from '@maplix/services';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { IUserDetails } from '@maplix/utils';

@Component({
  selector: 'account-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  isMenuCollapsed = true;

  public userDetails$: Observable<IUserDetails> = this.workspaceService.userDetails$;

  public isSuperUser$: Observable<boolean> = this.userDetails$.pipe(
    map((user) => user && user.roles && user.roles.includes('SUPERUSER'))
  );

  public engageUrl: string = environment.engage;

  public exploreUrl: string = environment.explore;

  public showNav: boolean;

  private destroyed: Subject<void> = new Subject();

  constructor(
    private router: Router,
    public shared: SharedService,
    private cookie: CookieService,
    private workspaceService: WorkspaceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Initial nav config
    this.route.children.forEach((childRoute) => {
      this.showNav = !childRoute.snapshot.data || !childRoute.snapshot.data.noNavigation;
    });

    // Change nav config on change route
    this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationEnd) {
            this.route.children.forEach((childRoute) => {
              this.showNav = !childRoute.snapshot.data || !childRoute.snapshot.data.noNavigation;
            });
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * Sign out the user
   */
  logout() {
    this.cookie.delete(`userDetails${environment.env}`, '/', environment.cookieDomain);
    this.cookie.delete(`activeWorkspace${environment.env}`, '/', environment.cookieDomain);
    this.cookie.delete(`MaplixToken${environment.env}`, '/', environment.cookieDomain);
    this.cookie.delete(`MaplixTokenId${environment.env}`, '/', environment.cookieDomain);
    this.workspaceService.setUserDetails(undefined);
    this.router.navigate(['/auth/login']);
  }
}
