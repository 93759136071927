import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import * as QuillNamespace from 'quill';
import ImageResize from 'quill-image-resize-module--fix-imports-error';
import VideoResize from 'quill-video-resize-module2';
import * as Emoji from 'quill-emoji';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ImageUploader from 'quill-image-uploader';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { randomIdGenerator } from '@maplix/utils';
import { FormControl } from '@maplix/forms';
import { ColorEvent } from 'ngx-color';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

let Quill: any = QuillNamespace;
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/videoResize', VideoResize);
Quill.register('modules/emoji', Emoji);

@Component({
  selector: 'maplix-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaplixQuillEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public control: FormControl<string>;

  @Input()
  public limited: boolean = false;

  @Input()
  public withSize: boolean = false;

  @Input()
  public withColor: boolean = false;

  @Input()
  public placeholder: string = '';

  @Input()
  public height: number = 45;

  @Input()
  private apiUrl: string;

  @Input()
  private fixedHeight: boolean = false;

  @Input()
  public collapsable: boolean = true;

  @Input()
  public collapsed: boolean = true;

  public editorModules: any;

  @ViewChild('quillEditor', { static: false })
  quillEditor: QuillEditorComponent;

  @ViewChild('colorPicker', { static: false })
  private colorPicker: any;

  private destroyed: Subject<void> = new Subject();

  public randomId: string = `ql-${randomIdGenerator(30)}`;

  constructor(private http: HttpClient, private modalService: NgbModal) {}

  ngOnInit() {
    const container: any[] = [['bold', 'italic', 'underline', 'strike'], ['emoji']];

    if (!this.limited) {
      container.push(
        [
          {
            color: [
              '#304351',
              '#e60000',
              '#ff9900',
              '#ffff00',
              '#008a00',
              '#0066cc',
              '#9933ff',
              '#ffffff',
              '#facccc',
              '#ffebcc',
              '#ffffcc',
              '#cce8cc',
              '#cce0f5',
              '#ebd6ff',
              '#bbbbbb',
              '#f06666',
              '#ffc266',
              '#ffff66',
              '#66b966',
              '#66a3e0',
              '#c285ff',
              '#888888',
              '#a10000',
              '#b26b00',
              '#b2b200',
              '#006100',
              '#0047b2',
              '#6b24b2',
              '#444444',
              '#5c0000',
              '#663d00',
              '#666600',
              '#003700',
              '#002966',
              '#3d1466',
            ],
          },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        ['link', 'image', 'video']
      );
    }

    if (this.withSize) {
      container.push([{ header: [1, 2, 3, 4, 5, 6, false] }]);
    }

    this.editorModules = {
      toolbar: {
        container,
        // handlers: {
        //   color: (value) => {
        //     if (value === 'custom-color') {
        //       setTimeout(() => {
        //         this.modalService.open(this.colorPicker);
        //       }, 50);
        //     }
        //   },
        // },
      },
      imageResize: {
        modules: ['Resize', 'DisplaySize'],
      },
      imageUploader: {
        upload: (file: File) => {
          return new Promise((resolve, reject) => {
            this.upload(file)
              .pipe(takeUntil(this.destroyed))
              .subscribe(
                (event: any) => {
                  if (event instanceof HttpResponse)
                    resolve(`${this.apiUrl}${event.body && event.body.file && event.body.file.file}`);
                },
                () => {
                  reject();
                }
              );
          });
        },
      },
      videoResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
        tagName: 'iframe',
      },
      'emoji-toolbar': true,
    };
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.quillEditor.editorElem.style.minHeight = this.height + 'px';
      this.quillEditor.editorElem.classList.add('perfect-scroll');
      this.quillEditor.editorElem.style.removeProperty('position');
      if (this.fixedHeight) {
        this.quillEditor.editorElem.style.maxHeight = this.height + 'px';
      } else if (this.height < 200) {
        this.quillEditor.editorElem.style.maxHeight = '200px';
      }

      if (this.collapsable && this.collapsed) {
        // Hide toolbar
        const toolbar = document.querySelector(`#${this.randomId} .ql-toolbar`) as HTMLElement;

        if (toolbar) {
          toolbar.style.visibility = 'hidden';
          toolbar.style.height = '0';
          toolbar.style.padding = '0';
        }

        // Round bottom edges of editor
        this.quillEditor.editorElem.style.borderBottomLeftRadius = '4px';
        this.quillEditor.editorElem.style.borderBottomRightRadius = '4px';
      }

      const customColorElement = document.querySelector('.ql-picker-item[data-value="custom-color"]') as HTMLElement;
      if (customColorElement) {
        customColorElement.innerHTML = '+';
        customColorElement.title = 'Add another color';
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private upload(file: File): Observable<any> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${this.apiUrl}uploads`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public onToggleCollapse() {
    this.collapsed = !this.collapsed;

    // Show or hide toolbar
    const toolbar = document.querySelector(`#${this.randomId} .ql-toolbar`) as HTMLElement;
    toolbar.style.visibility = this.collapsed ? 'hidden' : 'visible';
    toolbar.style.height = this.collapsed ? '0' : 'auto';
    toolbar.style.padding = this.collapsed ? '0' : '8px';

    // Round or square off bottom edges of editor
    this.quillEditor.editorElem.style.borderBottomLeftRadius = this.collapsed ? '4px' : '0';
    this.quillEditor.editorElem.style.borderBottomRightRadius = this.collapsed ? '4px' : '0';
  }

  public onChangeColor(event: ColorEvent) {
    this.quillEditor.quillEditor.format('color', event.color.hex);
  }
}
