import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ILanguage,
  IMapDataLayer,
  IMapResult,
  IPopupInfo,
  ISurvey,
  ISurveyImage,
  ISurveyMap,
  MaplixApp,
  MapType,
  ToastrNotification,
} from '@maplix/utils';
import { FormArray, FormGroup } from '@maplix/forms';
import { ILegendUrl, ImageCls, MapCls } from '../../class';
import { onToggleLayerVisibility } from '../../utils/toggle-layer-visibility';
import { DataLayerHandler } from '../../class/datalayer-handler';
import { MapLayerService } from '@maplix/maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'maplix-map-with-sidebar',
  templateUrl: './map-with-sidebar.component.html',
  styleUrls: ['./map-with-sidebar.component.scss'],
})
export class MapWithSidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  public readonly loading: boolean;

  @Input()
  public readonly mapType: MapType = MapType.MAP;

  @Input()
  public mapForm: FormGroup<IMapResult | ISurveyMap | ISurveyImage>;

  @Input()
  public readonly map: MapCls | ImageCls;

  @Input()
  public WMSLegendUrls: ILegendUrl;

  @Input()
  public popupInfo: IPopupInfo;

  @Input()
  public readonly language: ILanguage;

  @Input()
  public readonly editable: boolean;

  @Input()
  public readonly mapId: string;

  @Input()
  public readonly fullscreenBox: string = 'fullscreenBox';

  @Input()
  public showSidebar: boolean = true;

  @Input()
  public showSidebarToggle: boolean = true;

  @Input()
  public showAddressSearch: boolean = true;

  @Input()
  public showExport: boolean = true;

  @Input()
  public height: string;

  @Input()
  public readonly legendText: string = 'Layers';

  @Input()
  public readonly searchPlaceholder: string = 'Search location';

  @Input()
  public showBaseToggle: boolean = true;

  @Input()
  public skipSurvey: string;

  @Input()
  public surveyForImage: ISurvey;

  @Input()
  public helptext: string;

  @Input()
  public app: MaplixApp;

  @Output()
  private sidebarChange: EventEmitter<boolean> = new EventEmitter();

  public isMobile: boolean = window.screen.availWidth < 768;

  public maxBaseToggleHeight: string;

  private dataLayerHandler: DataLayerHandler;

  public collapseByDefault: boolean;

  public get activeMap(): IMapResult {
    if (!this.mapForm || !(this.mapForm.getRawValue() as IMapResult).options) {
      return;
    }

    return this.mapForm.getRawValue() as IMapResult;
  }

  constructor(
    @Inject('environment') private environment: any,
    private mapService: MapLayerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (!this.height) {
      this.setMapHeight();
    }
    window.onresize = () => {
      this.isMobile = window.screen.availWidth < 768;
      if (this.isMobile && this.showSidebar) {
        this.onToggleSidebox();
      }

      this.setMapHeight(true);
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Find max height for baselayer toggle
      const mapElement = document.querySelector(`#${this.mapId}`) as HTMLElement;

      if (!mapElement) {
        return;
      }

      if (this.map && this.map.map) {
        this.map.map.updateSize();
      }

      const bbox = mapElement.getBoundingClientRect();
      this.maxBaseToggleHeight = bbox.height - 175 + 'px';
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.mapForm || changes.mapId) &&
      this.mapId &&
      this.mapForm &&
      this.mapForm.getControl('dataLayers').value.length
    ) {
      if (this.showSidebar === false || this.isMobile) {
        this.collapseByDefault = true;
      }
    }

    if (changes.map && changes.map.currentValue) {
      const notification = new ToastrNotification(this.toastr);
      this.dataLayerHandler = new DataLayerHandler(
        this.mapType,
        this.map,
        this.mapForm,
        this.environment,
        this.mapService,
        this.modalService,
        notification
      );

      this.dataLayerHandler.setSurveyToSkip(this.skipSurvey);
    }

    if (changes.showSidebar) {
      setTimeout(() => {
        if (this.map && this.map.map) {
          this.map.map.updateSize();
          this.map.map.removeOverlay(this.map.popupOverlay);

          setTimeout(() => {
            this.map.map.addOverlay(this.map.popupOverlay);
          }, 50);
        }
      }, 500);
    }
  }

  private setMapHeight(onResize?: boolean) {
    // Don't resize when searching a location
    const activeElement = document.activeElement as HTMLElement;
    if (this.isMobile && onResize && activeElement && activeElement.getAttribute('type') === 'text') {
      return;
    }

    if (!this.height) {
      this.height = `${window.innerHeight}px`;
    }

    if (this.map && this.map.map) {
      this.map.map.updateSize();

      if (this.map.popupOverlay) {
        this.map.map.removeOverlay(this.map.popupOverlay);

        setTimeout(() => {
          this.map.map.addOverlay(this.map.popupOverlay);
        }, 50);
      }
    }
  }

  public onChangeBaseLayer(layer: string) {
    if ('baseLayer' in this.mapForm.getRawValue()) {
      (this.mapForm as FormGroup<IMapResult>).getControl('baseLayer').setValue(layer);
    } else {
      (this.mapForm as FormGroup<ISurveyMap>).getControl('defaultBaseLayer').setValue(layer);
    }
    this.map.map.getLayers().forEach((l) => {
      if (l.get('name') !== layer && l.getVisible() && l.get('base')) {
        l.setVisible(false);
      } else if (l.get('name') === layer) {
        l.setVisible(true);
      }
    });
  }

  public onToggleSidebox() {
    const sidebox = document.querySelector(`#sidebar${this.mapId}`) as HTMLElement;

    if (sidebox.classList.contains('hide-sidebar') || sidebox.classList.contains('start-sidebar-hidden')) {
      sidebox.classList.remove('hide-sidebar');
      sidebox.classList.remove('start-sidebar-hidden');
      sidebox.classList.add('show-sidebar');
      this.showSidebar = true;
    } else if (sidebox.classList.contains('show-sidebar')) {
      sidebox.classList.remove('show-sidebar');
      sidebox.classList.add('hide-sidebar');
      this.showSidebar = false;
    } else {
      sidebox.classList.add('hide-sidebar');
      this.showSidebar = false;
    }
    this.sidebarChange.emit(this.showSidebar);

    setTimeout(() => {
      if (this.map && this.map.map) {
        this.map.map.updateSize();
        this.map.map.removeOverlay(this.map.popupOverlay);

        setTimeout(() => {
          this.map.map.addOverlay(this.map.popupOverlay);
        }, 50);
      }
    }, 150);
  }

  public onChangeVisibility(layer: FormGroup<IMapDataLayer>) {
    onToggleLayerVisibility({ layer, map: this.map.map });
  }

  public onChangeHidden({ showOnLegend, layer }: { showOnLegend: boolean; layer?: FormGroup<IMapDataLayer> }) {
    if (!layer) {
      return;
    }
    layer.getFormControl('showOnLegend').setValue(showOnLegend);
  }

  public onChangeLayerOrder() {
    this.mapService.onRefreshLayersOrder(this.map, this.mapForm.getRawValue());
  }

  public onOpenModal($event: { type: string; layer?: FormGroup<IMapDataLayer>; index?: number }) {
    this.dataLayerHandler.selectedIndex = $event.index;
    this.dataLayerHandler.onOpenLayerModal($event);
  }

  public onOpenCloudModal() {
    if (this.mapType === 'IMAGE') {
      const layer = this.mapService.createSurveyLayer(this.mapType, this.surveyForImage);
      layer.get('survey').markAsDirty();
      (this.mapForm.get('dataLayers') as FormArray<IMapDataLayer>).push(layer);

      this.dataLayerHandler.onOpenLayerModal({
        type: 'SURVEY',
        layer,
        fullSurvey: this.surveyForImage,
        isNew: true,
      });

      return;
    }

    this.dataLayerHandler.onOpenCloudModal();
  }

  public onDeleteLayer(dataLayer: IMapDataLayer) {
    this.dataLayerHandler.onDeleteLayer(dataLayer);
  }
}
