import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'maplix-compare-slider',
  templateUrl: './compare-slider.component.html',
  styleUrls: ['./compare-slider.component.scss'],
})
export class CompareSliderComponent implements OnInit {
  public dragPosition = { x: 0, y: 0 };

  constructor() {}

  ngOnInit(): void {
    const container = document.getElementById('slider-drag-boundary') as HTMLElement;
    if (!container) {
      return;
    }

    this.dragPosition.x = container.offsetWidth / 2 - 130;
  }

  public onMoveSlider(move: CdkDragMove) {}
}
