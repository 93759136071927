import { Component, OnInit, AfterViewInit } from '@angular/core';
import { WorkspaceService } from '@maplix/services';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiService } from '@maplix/api';
import { RealmService } from '../shared/services/realm.service';
import { SharedService } from '../shared/services/shared.service';
import { IUserDetails } from '@maplix/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleDemoModalComponent } from '../account/components/schedule-demo-modal/schedule-demo-modal.component';

@Component({
  selector: 'account-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  showMobileMenu = false;

  private destroyed: Subject<void> = new Subject();

  public engageUrl: string = environment.engage;

  constructor(
    public shared: SharedService,
    private api: ApiService,
    private cookie: CookieService,
    private workspaceService: WorkspaceService,
    private realmService: RealmService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    const activeWorkspaceJSON = this.cookie.get(`activeWorkspace${environment.env}`);
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;
    if (activeWorkspace) {
      this.workspaceService.setActiveWorkspace(activeWorkspace);
      this.api.organisation
        .getOrganisationById()
        .pipe(takeUntil(this.destroyed))
        .subscribe(
          (response) => {
            this.workspaceService.setActiveWorkspace(response);
            if (response) {
              this.cookie.set(
                `activeWorkspace${environment.env}`,
                JSON.stringify(response),
                30,
                '/',
                environment.cookieDomain
              );
            } else {
              this.realmService.auth.logout();
            }
          },
          () => {
            this.realmService.auth.logout();
          }
        );
    }

    if (this.cookie.get(`MaplixToken${environment.env}`)) {
      this.realmService.auth.checkApiKey(this.cookie.get(`MaplixToken${environment.env}`));
    }

    const userDetailsJSON = this.cookie.get(`userDetails${environment.env}`);
    const userDetails = userDetailsJSON ? JSON.parse(userDetailsJSON) : null;

    if (userDetails) {
      this.api.user
        .getUserById(userDetails._id)
        .pipe(takeUntil(this.destroyed))
        .subscribe(async (response: IUserDetails) => {
          this.workspaceService.setUserDetails(response);

          if (response) {
            this.cookie.set(
              `userDetails${environment.env}`,
              JSON.stringify(response),
              30,
              '/',
              environment.cookieDomain
            );
          }

          if (!response?.seenMessages || !response?.seenMessages?.demo) {
            const demoModal = this.modalService.open(ScheduleDemoModalComponent, {
              backdrop: 'static',
              centered: true,
            });

            const openWebsite = await demoModal.result;

            const seenMessages = response?.seenMessages ?? {};
            seenMessages.demo = new Date();
            this.api.user.updateUserDetails({ seenMessages }).pipe(takeUntil(this.destroyed)).subscribe();

            if (openWebsite) {
              window.open('https://www.maplix.com/book-demo', '_blank');
            }
          }
        });
    }
  }

  ngAfterViewInit() {
    document.body.classList.remove('authentication-bg');
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
