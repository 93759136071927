<div [id]="fullscreenBox" class="fullscreen-preview" [ngStyle]="{ height: height }">
  <div [id]="mapId" class="analytics-map">
    <div class="loader-overlay d-flex flex-column align-items-center justify-content-center" *ngIf="loading">
      <div class="spinner-grow avatar-sm text-white" role="status"></div>
      <div class="h4 mt-3 text-white">Loading</div>
    </div>

    <maplix-address-search
      [map]="map"
      *ngIf="mapType === 'MAP'"
      [spaceRight]="true"
      [placeholder]="searchPlaceholder"
    ></maplix-address-search>

    <maplix-baselayer-toggle
      [loading]="loading"
      *ngIf="mapType === 'MAP' && showBaseToggle"
      [(activeLayer)]="
        mapForm?.get('baseLayer') ? mapForm?.get('baseLayer').value : mapForm?.get('defaultBaseLayer').value
      "
      (activeLayerChange)="onChangeBaseLayer($event)"
    >
    </maplix-baselayer-toggle>

    <div class="ol-control layer-button" *ngIf="mapForm?.value.dataLayers?.length" ngbDropdown>
      <button ngbDropdownToggle>
        <i class="fe-layers"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownAction">
        <div class="legend-wrapper perfect-scroll">
          <maplix-map-layers
            (toggleVisibility)="onChangeVisibility($event)"
            [dataLayers]="mapForm?.get('dataLayers')"
            [wmsLegends]="$any(map)?.WMSLegendUrls"
            [language]="language"
            [editable]="false"
            [legendText]="legendText"
            *ngIf="mapForm"
          >
          </maplix-map-layers>
        </div>
      </div>
    </div>

    <div class="feature-indicator small" *ngIf="mapForm?.value.dataLayers?.length">
      {{ featuresInView }} {{ 'map.INVIEW' | translate }}
    </div>
  </div>
</div>

<div style="visibility: hidden; position: absolute; top: 0; left: 0">
  <maplix-map-popup [popupInfo]="popupInfo" [mapId]="mapId"></maplix-map-popup>
</div>
