<div class="modal-body position-relative" [formGroup]="surveyLayer" *ngIf="survey$ | async as survey">
  <ul ngbNav #layerTabs="ngbNav" (navChange)="onChangeTab()" class="nav-tabs nav-tabs-sm">
    <li ngbNavItem="source">
      <a ngbNavLink>Source</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <div class="element-container mb-3" *ngIf="survey?.published">
            <div class="d-flex align-items-center">
              <i class="remixicon-information-line mr-2 font-20"></i>
              It can take up to 5 minutes for new responses to be processed.
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Survey</label>
            <ng-container
              *ngFor="
                let title of survey.general.title
                  | filter: { field: 'i18n', filter: survey.general.defaultLanguage.code }
              "
            >
              <div class="card border-0 bg-light py-2 px-3">
                <div class="d-flex align-items-center">
                  <i class="remixicon-file-list-2-line mr-2"></i>
                  <p class="mb-0">{{ title?.value ?? 'Untitled survey' }}</p>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="form-group" *ngIf="surveyLayer.get('survey')?.value">
            <label class="form-label">Geometry type</label>
            <ng-select
              [items]="geometryTypes"
              bindLabel="label"
              bindValue="value"
              formControlName="geomType"
              [clearable]="false"
              [searchable]="false"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-group" *ngIf="surveyLayer.get('geomType').value === 'LineString' && mapType === 'MAP'">
            <label class="form-label">Line type</label>
            <ng-select
              [items]="lineTypes"
              bindLabel="label"
              bindValue="value"
              formControlName="lineType"
              [clearable]="false"
              [searchable]="false"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group" *ngIf="surveyLayer?.get('layers') && surveyLayer?.get('layers')['controls'].length">
            <label class="form-label">Sublayers</label>

            <ng-container
              *ngIf="
                (mapType === 'MAP' ? survey?.questions.mapInteractions : survey?.questions.imageInteractions)
                  | map
                    : getInteractionsOfType
                    : surveyLayer?.get('geomType').value
                    : surveyLayer?.get('lineType').value as mapPages
              "
            >
              <ul class="mb-0">
                <ng-container *ngFor="let mapPage of mapPages; first as isFirst">
                  <li *ngIf="mapPage">
                    <div class="d-flex align-items-center" [class.mt-3]="!isFirst">
                      <!-- <i class="remixicon-road-map-line mr-2 font-16"></i> -->
                      <ng-container
                        *ngFor="let page of survey?.questions.pages | filter: { field: 'id', filter: mapPage.pageid }"
                      >
                        <ng-container
                          *ngFor="
                            let name of page.name
                              | filter
                                : {
                                    field: 'i18n',
                                    filter: survey?.general.defaultLanguage.code
                                  }
                          "
                        >
                          <div class="d-flex align-items-center">
                            <span class="text-muted text-uppercase font-13">{{ name?.value ?? 'Untitled page' }}</span>
                            <span class="badge badge-light-danger ml-2" *ngIf="page._archived">Deleted</span>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>

                    <ng-container *ngIf="surveyLayer?.get('geomType').value === 'LineString' && mapType === 'MAP'">
                      <ng-container
                        *ngFor="
                          let layer of surveyLayer?.get('layers')['controls']
                            | formGroupFilter: { field: 'parent', filter: mapPage.id }
                            | formGroupFilter
                              : {
                                  field: 'geomType',
                                  filter: surveyLayer?.get('geomType').value
                                }
                            | formGroupFilter
                              : {
                                  field: 'lineType',
                                  filter: surveyLayer?.get('lineType').value
                                }
                        "
                        [formGroup]="layer"
                      >
                        <div class="custom-control custom-checkbox mt-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            formControlName="selectedInModal"
                            [id]="'modal' + layer.get('id').value"
                            name="selectedInModal"
                          />
                          <label [for]="'modal' + layer.get('id').value" class="custom-control-label">
                            {{ getInteractionTitle(layer.get('parent').value, layer.get('interaction').value, survey) }}
                            <span
                              class="badge badge-light-danger ml-2"
                              *ngIf="layer.get('_archived')?.value && !mapPage._archived"
                              >Deleted</span
                            >
                          </label>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="surveyLayer?.get('geomType').value !== 'LineString' || mapType === 'IMAGE'">
                      <ng-container
                        *ngFor="
                          let layer of surveyLayer?.get('layers')['controls']
                            | formGroupFilter: { field: 'parent', filter: mapPage.id }
                            | formGroupFilter
                              : {
                                  field: 'geomType',
                                  filter: surveyLayer?.get('geomType').value
                                }
                        "
                        [formGroup]="layer"
                      >
                        <div class="custom-control custom-checkbox mt-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            formControlName="selectedInModal"
                            [id]="'modal' + layer.get('id').value"
                            name="selectedInModal"
                          />
                          <label [for]="'modal' + layer.get('id').value" class="custom-control-label">
                            {{ getInteractionTitle(layer.get('parent').value, layer.get('interaction').value, survey) }}
                            <span
                              class="badge badge-light-danger ml-2"
                              *ngIf="layer.get('_archived')?.value && !mapPage._archived"
                              >Deleted</span
                            >
                          </label>
                        </div>
                      </ng-container>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
              <ng-container *ngIf="!mapPages.length">
                <div class="text-muted">No interactions found for this geometry type.</div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="style">
      <a ngbNavLink>Style</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <ng-container *ngIf="surveyLayer?.get('layers')['controls'] as layers">
            <!-- If no layer is selected, show message -->
            <ng-container *ngIf="(layers | formGroupFilter: { field: 'selectedInModal', filter: true })?.length < 1">
              <p class="text-muted">Please select a sublayer first.</p>
            </ng-container>

            <!-- If only one sublayer is selected -->
            <ng-container
              *ngIf="(layers | formGroupFilter: { field: 'selectedInModal', filter: true })?.length === 1"
              [formGroup]="surveyLayer"
            >
              <ng-container
                *ngFor="
                  let layer of surveyLayer?.get('layers')['controls']
                    | formGroupFilter: { field: 'selectedInModal', filter: true }
                "
                [formGroup]="layer"
              >
                <!-- Ask style source -->
                <div class="form-group" *ngIf="!selectedRule">
                  <div class="font-13 text-muted text-uppercase mb-3">Layer style</div>
                  <label class="form-label">Style type</label>
                  <ng-select
                    [items]="styleSources"
                    formControlName="styleSource"
                    bindValue="value"
                    [clearable]="false"
                    [searchable]="false"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <i [class]="item.icon"></i>
                      <span class="ml-2">{{ item.label }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <i [class]="item.icon"></i>
                      <span class="ml-2">{{ item.label }}</span>
                    </ng-template>
                  </ng-select>
                </div>

                <!-- If rule based style source is selected -->
                <ng-container *ngIf="layer?.get('styleSource').value === 'rule-based' && !selectedRule">
                  <!-- Show a table of all rules with an add and edit button -->
                  <label class="form-label">Rules</label>
                  <div class="table-responsive">
                    <table class="table table-sm border-bottom">
                      <tbody>
                        <tr *ngFor="let styleCondition of layer.get('styleConditions')['controls']">
                          <td>
                            <ng-container
                              *ngFor="
                                let title of styleCondition.get('title')['controls']
                                  | formGroupFilter
                                    : {
                                        field: 'i18n',
                                        filter: mapService.language
                                      }
                              "
                            >
                              {{ title.get('value').value }}
                            </ng-container>
                          </td>
                          <td>
                            <div class="d-flex justify-content-end">
                              <button
                                class="btn btn-xs mr-1 btn-light"
                                (click)="onEditConditions(styleCondition)"
                                ngbTooltip="Edit conditions"
                              >
                                <i class="remixicon-list-settings-line"></i>
                              </button>
                              <button
                                class="btn btn-xs mr-1 btn-light"
                                (click)="onEditRule(styleCondition)"
                                ngbTooltip="Edit style"
                              >
                                <i class="remixicon-pencil-line"></i>
                              </button>
                              <button
                                class="btn btn-xs btn-danger"
                                *ngIf="layer.get('styleConditions')['controls'].length > 1"
                                (click)="onRemoveRule(layer.get('styleConditions'), styleCondition)"
                                ngbTooltip="Delete"
                              >
                                <i class="remixicon-delete-bin-6-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-center">
                    <maplix-icon-text-button
                      [buttonType]="'primary'"
                      [wide]="true"
                      [icon]="'remixicon-add-circle-fill'"
                      [buttonText]="'Add rule'"
                      (click)="onAddRule(layer.get('styleConditions'))"
                    >
                    </maplix-icon-text-button>
                  </div>
                </ng-container>

                <ng-container *ngIf="selectedRule">
                  <label
                    class="form-label mt-0 mb-3 d-flex align-items-center clickable"
                    (click)="selectedRule = undefined"
                  >
                    <i class="fe-arrow-left mr-2"></i> Back
                  </label>
                  <ng-container *ngIf="selectedRuleView === 'style'">
                    <maplix-vector-layer-style
                      [dataLayer]="layer"
                      [styleCondition]="selectedRule"
                      [survey]="survey"
                      [mapType]="mapType"
                      [apiUrl]="apiUrl"
                      [language]="mapService.language"
                    >
                    </maplix-vector-layer-style>
                  </ng-container>
                  <ng-container *ngIf="selectedRuleView === 'conditions'">
                    <maplix-rule-editor
                      [dataLayer]="layer"
                      [rules]="selectedRule.get('rules')"
                      [survey]="survey"
                      [mapType]="mapType"
                      [language]="mapService.language"
                      [geometryType]="
                        layer.get('geomType').value === 'LineString' && layer.get('lineType').value === 'Route'
                          ? 'Route'
                          : layer.get('geomType').value
                      "
                      [interactionsInLayer]="layer | map: getInteractions"
                    >
                    </maplix-rule-editor>
                  </ng-container>
                </ng-container>

                <!-- If rule based is not selected, show styling component for the selected sublayer -->
                <ng-container *ngIf="layer?.get('styleSource').value !== 'rule-based'">
                  <ng-container *ngFor="let styleCondition of layer?.get('styleConditions')['controls']">
                    <maplix-vector-layer-style
                      [dataLayer]="layer"
                      [styleCondition]="styleCondition"
                      [survey]="survey"
                      [mapType]="mapType"
                      [apiUrl]="apiUrl"
                      [language]="mapService.language"
                    >
                    </maplix-vector-layer-style>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- If multiple sublayers are selected -->
            <ng-container *ngIf="(layers | formGroupFilter: { field: 'selectedInModal', filter: true })?.length > 1">
              <!-- Ask for one style or style per sublayer -->
              <div class="form-group" *ngIf="!selectedRule">
                <label class="form-label">Style sublayers</label>
                <ng-select
                  [items]="styleTypes"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="combineSubLayers"
                  [clearable]="false"
                  [searchable]="false"
                  appendTo=".layer-editor"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div class="d-flex align-items-center">
                      <i [class]="item.icon + ' mr-2'"></i>
                      {{ item.label }}
                    </div>
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item">
                    <div class="d-flex align-items-center">
                      <i [class]="item.icon + ' mr-2'"></i>
                      {{ item.label }}
                    </div>
                  </ng-template>
                </ng-select>
                <!-- <maplix-selector [items]="styleTypes" [control]="surveyLayer.get('combineSubLayers')"></maplix-selector> -->
              </div>

              <!-- If sublayers are styled as one layer -->
              <ng-container *ngIf="surveyLayer?.get('combineSubLayers').value">
                <!-- Ask style source -->
                <div class="form-group" *ngIf="!selectedRule">
                  <div class="font-13 text-muted text-uppercase mb-3">Layer style</div>
                  <label class="form-label">Style type</label>
                  <ng-select
                    [items]="styleSources"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="styleSource"
                    [clearable]="false"
                    [searchable]="false"
                    appendTo=".layer-editor"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div class="d-flex align-items-center">
                        <i [class]="item.icon + ' mr-2'"></i>
                        {{ item.label }}
                      </div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                      <div class="d-flex align-items-center">
                        <i [class]="item.icon + ' mr-2'"></i>
                        {{ item.label }}
                      </div>
                    </ng-template>
                  </ng-select>
                  <!-- <maplix-selector [items]="styleSources" [control]="surveyLayer.get('styleSource')"></maplix-selector> -->
                </div>

                <!-- If rule based style source is selected -->
                <ng-container *ngIf="surveyLayer?.get('styleSource').value === 'rule-based' && !selectedRule">
                  <!-- Show a table of all rules with an add and edit button -->
                  <label class="form-label">Rules</label>
                  <div class="table-responsive">
                    <table class="table table-sm border-bottom">
                      <tbody>
                        <tr *ngFor="let styleCondition of surveyLayer.get('styleConditions')['controls']">
                          <td>
                            <ng-container
                              *ngFor="
                                let title of styleCondition.get('title')['controls']
                                  | formGroupFilter
                                    : {
                                        field: 'i18n',
                                        filter: mapService.language
                                      }
                              "
                            >
                              {{ title.get('value').value }}
                            </ng-container>
                          </td>
                          <td>
                            <div class="d-flex justify-content-end">
                              <button
                                class="btn btn-xs mr-1 btn-light"
                                (click)="onEditConditions(styleCondition)"
                                ngbTooltip="Edit conditions"
                              >
                                <i class="remixicon-list-settings-line"></i>
                              </button>
                              <button
                                class="btn btn-xs mr-1 btn-light"
                                (click)="onEditRule(styleCondition)"
                                ngbTooltip="Edit style"
                              >
                                <i class="remixicon-pencil-line"></i>
                              </button>
                              <button
                                class="btn btn-xs btn-danger"
                                *ngIf="surveyLayer.get('styleConditions')['controls'].length > 1"
                                (click)="onRemoveRule(surveyLayer.get('styleConditions'), styleCondition)"
                                ngbTooltip="Delete"
                              >
                                <i class="remixicon-delete-bin-6-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-center">
                    <maplix-icon-text-button
                      [buttonType]="'primary'"
                      [wide]="true"
                      [icon]="'remixicon-add-circle-fill'"
                      [buttonText]="'Add rule'"
                      (click)="onAddRule(surveyLayer.get('styleConditions'))"
                    >
                    </maplix-icon-text-button>
                  </div>
                </ng-container>

                <ng-container *ngIf="selectedRule">
                  <label
                    class="form-label mt-0 mb-3 d-flex align-items-center clickable"
                    (click)="selectedRule = undefined"
                  >
                    <i class="fe-arrow-left mr-2"></i> Back
                  </label>
                  <ng-container *ngIf="selectedRuleView === 'style'">
                    <maplix-vector-layer-style
                      [dataLayer]="surveyLayer"
                      [styleCondition]="selectedRule"
                      [survey]="survey"
                      [mapType]="mapType"
                      [apiUrl]="apiUrl"
                      [language]="mapService.language"
                    >
                    </maplix-vector-layer-style>
                  </ng-container>
                  <ng-container *ngIf="selectedRuleView === 'conditions'">
                    <maplix-rule-editor
                      [dataLayer]="surveyLayer"
                      [rules]="selectedRule.get('rules')"
                      [survey]="survey"
                      [mapType]="mapType"
                      [language]="mapService.language"
                      [geometryType]="
                        surveyLayer.get('geomType').value === 'LineString' &&
                        surveyLayer.get('lineType').value === 'Route'
                          ? 'Route'
                          : surveyLayer.get('geomType').value
                      "
                      [interactionsInLayer]="surveyLayer | map: getInteractions"
                    >
                    </maplix-rule-editor>
                  </ng-container>
                </ng-container>

                <!-- If rule based is not selected, show styling component -->
                <ng-container *ngIf="surveyLayer?.get('styleSource').value !== 'rule-based'">
                  <ng-container *ngFor="let styleCondition of surveyLayer?.get('styleConditions')['controls']">
                    <maplix-vector-layer-style
                      [dataLayer]="surveyLayer"
                      [styleCondition]="styleCondition"
                      [survey]="survey"
                      [mapType]="mapType"
                      [apiUrl]="apiUrl"
                      [language]="mapService.language"
                    >
                    </maplix-vector-layer-style>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- If sublayers are styled separately -->
              <ng-container *ngIf="!surveyLayer?.get('combineSubLayers').value">
                <!-- Show an accordion with all sublayers -->
                <label class="form-label">Sublayers</label>
                <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
                  <ngb-panel
                    *ngFor="
                      let layer of surveyLayer.get('layers')['controls']
                        | formGroupFilter: { field: 'selectedInModal', filter: true }
                    "
                    [id]="layer.get('id').value"
                  >
                    <ng-template ngbPanelTitle>
                      <div class="d-flex justify-content-start align-items-center">
                        <i
                          class="fas mr-3 fa-fw"
                          [class.fa-angle-down]="acc.isExpanded(layer.get('id').value)"
                          [class.fa-angle-right]="!acc.isExpanded(layer.get('id').value)"
                        ></i>
                        <ng-container
                          *ngFor="
                            let title of layer.get('title')['controls']
                              | formGroupFilter
                                : {
                                    field: 'i18n',
                                    filter: mapService.language
                                  }
                          "
                        >
                          {{ title.get('value').value }}
                        </ng-container>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <form [formGroup]="layer">
                        <!-- Ask style source for layer -->
                        <div class="form-group" *ngIf="!selectedRule">
                          <div class="font-13 text-muted text-uppercase mb-3">Sublayer style</div>
                          <label class="form-label">Style type</label>
                          <ng-select
                            [items]="styleSources"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="styleSource"
                            [clearable]="false"
                            [searchable]="false"
                            appendTo=".layer-editor"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <div class="d-flex align-items-center">
                                <i [class]="item.icon + ' mr-2'"></i>
                                {{ item.label }}
                              </div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <div class="d-flex align-items-center">
                                <i [class]="item.icon + ' mr-2'"></i>
                                {{ item.label }}
                              </div>
                            </ng-template>
                          </ng-select>
                          <!-- <maplix-selector
                            [items]="styleSources"
                            [control]="layer.get('styleSource')"
                          ></maplix-selector> -->
                        </div>

                        <!-- If rule based style source is selected -->
                        <ng-container *ngIf="layer?.get('styleSource').value === 'rule-based' && !selectedRule">
                          <!-- Show a table of all rules with an add and edit button -->
                          <label class="form-label">Rules</label>
                          <div class="table-responsive">
                            <table class="table table-sm border-bottom">
                              <tbody>
                                <tr *ngFor="let styleCondition of layer.get('styleConditions')['controls']">
                                  <td>
                                    <ng-container
                                      *ngFor="
                                        let title of styleCondition.get('title')['controls']
                                          | formGroupFilter
                                            : {
                                                field: 'i18n',
                                                filter: mapService.language
                                              }
                                      "
                                    >
                                      {{ title.get('value').value }}
                                    </ng-container>
                                  </td>
                                  <td>
                                    <div class="d-flex justify-content-end">
                                      <button
                                        class="btn btn-xs mr-1 btn-light"
                                        (click)="onEditConditions(styleCondition)"
                                        ngbTooltip="Edit conditions"
                                      >
                                        <i class="remixicon-list-settings-line"></i>
                                      </button>
                                      <button
                                        class="btn btn-xs mr-1 btn-light"
                                        (click)="onEditRule(styleCondition)"
                                        ngbTooltip="Edit style"
                                      >
                                        <i class="remixicon-pencil-line"></i>
                                      </button>
                                      <button
                                        class="btn btn-xs btn-danger"
                                        *ngIf="layer.get('styleConditions')['controls'].length > 1"
                                        (click)="onRemoveRule(layer.get('styleConditions'), styleCondition)"
                                        ngbTooltip="Delete"
                                      >
                                        <i class="remixicon-delete-bin-6-fill"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="d-flex justify-content-center">
                            <maplix-icon-text-button
                              [buttonType]="'primary'"
                              [wide]="true"
                              [icon]="'remixicon-add-circle-fill'"
                              [buttonText]="'Add rule'"
                              (click)="onAddRule(layer.get('styleConditions'))"
                            >
                            </maplix-icon-text-button>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="selectedRule">
                          <label
                            class="form-label mt-0 mb-3 d-flex align-items-center clickable"
                            (click)="selectedRule = undefined"
                          >
                            <i class="fe-arrow-left mr-2"></i> Back
                          </label>
                          <ng-container *ngIf="selectedRuleView === 'style'">
                            <maplix-vector-layer-style
                              [dataLayer]="layer"
                              [styleCondition]="selectedRule"
                              [survey]="survey"
                              [mapType]="mapType"
                              [apiUrl]="apiUrl"
                              [language]="mapService.language"
                            >
                            </maplix-vector-layer-style>
                          </ng-container>
                          <ng-container *ngIf="selectedRuleView === 'conditions'">
                            <maplix-rule-editor
                              [dataLayer]="layer"
                              [rules]="selectedRule.get('rules')"
                              [survey]="survey"
                              [mapType]="mapType"
                              [language]="mapService.language"
                              [geometryType]="
                                layer.get('geomType').value === 'LineString' && layer.get('lineType').value === 'Route'
                                  ? 'Route'
                                  : layer.get('geomType').value
                              "
                              [interactionsInLayer]="layer | map: getInteractions"
                            >
                            </maplix-rule-editor>
                          </ng-container>
                        </ng-container>

                        <!-- If rule based is not selected, show styling component -->
                        <ng-container *ngIf="layer?.get('styleSource').value !== 'rule-based'">
                          <ng-container *ngFor="let styleCondition of layer?.get('styleConditions')['controls']">
                            <maplix-vector-layer-style
                              [dataLayer]="layer"
                              [styleCondition]="styleCondition"
                              [survey]="survey"
                              [mapType]="mapType"
                              [apiUrl]="apiUrl"
                              [language]="mapService.language"
                            >
                            </maplix-vector-layer-style>
                          </ng-container>
                        </ng-container>
                      </form>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="legend">
      <a ngbNavLink>Legend</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <div class="form-group">
            <label class="form-label">Title</label>
            <ng-container
              *ngFor="
                let title of surveyLayer?.get('title')['controls']
                  | formGroupFilter: { field: 'i18n', filter: mapService.language }
              "
              [formGroup]="title"
            >
              <input type="text" class="form-control" formControlName="value" />
            </ng-container>
          </div>
          <div class="form-group">
            <label class="form-label">Help text</label>
            <ng-container
              *ngFor="
                let helptext of surveyLayer.get('helptext')['controls']
                  | formGroupFilter: { field: 'i18n', filter: mapService.language }
              "
              [formGroup]="helptext"
            >
              <maplix-quill-editor [control]="helptext.get('value')" [apiUrl]="apiUrl" [placeholder]="'Help text'">
              </maplix-quill-editor>
            </ng-container>
          </div>

          <ng-container *ngIf="!surveyLayer.get('combineSubLayers') || !surveyLayer.get('combineSubLayers').value">
            <ng-container *ngIf="surveyLayer?.get('layers')['controls']">
              <ng-container
                *ngIf="
                  (surveyLayer?.get('layers')['controls'] | formGroupFilter: { field: 'selectedInModal', filter: true })
                    .length > 1
                "
              >
                <ng-container
                  *ngFor="
                    let layer of surveyLayer?.get('layers')['controls']
                      | formGroupFilter: { field: 'selectedInModal', filter: true };
                    let first = first;
                    let last = last
                  "
                >
                  <ng-container [formGroup]="layer">
                    <label class="form-label" *ngIf="first">Sublayers</label>
                    <ng-container
                      *ngFor="
                        let title of layer.get('title')['controls']
                          | formGroupFilter: { field: 'i18n', filter: mapService.language }
                      "
                      [formGroup]="title"
                    >
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control mb-2"
                          formControlName="value"
                          [placeholder]="
                            getInteractionTitle(layer.get('parent').value, layer.get('interaction').value, survey)
                          "
                        />
                        <div class="input-group-append" *ngIf="first !== last || (!first && !last)">
                          <button
                            class="btn btn-light form-control"
                            [ngbTooltip]="
                              visibleHelptexts.includes(layer.get('interaction').value)
                                ? 'Hide help text'
                                : 'Edit help text'
                            "
                            placement="left"
                            (click)="onToggleHelptext(layer.get('interaction').value)"
                          >
                            <i class="remixicon-information-fill"></i>
                          </button>
                        </div>
                      </div>
                    </ng-container>

                    <div *ngIf="visibleHelptexts.includes(layer.get('interaction').value)">
                      <ng-container
                        *ngFor="
                          let helptext of layer.get('helptext')['controls']
                            | formGroupFilter: { field: 'i18n', filter: mapService.language }
                        "
                        [formGroup]="helptext"
                      >
                        <maplix-quill-editor
                          [control]="helptext.get('value')"
                          [apiUrl]="apiUrl"
                          [placeholder]="'Help text'"
                        >
                        </maplix-quill-editor>
                      </ng-container>
                    </div>

                    <hr *ngIf="!last" class="mb-2 mt-0 text-dark" />
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="options">
      <a ngbNavLink>Options</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <div class="form-group mb-4">
            <label class="form-label">
              Zoom extent
              <i
                class="remixicon-question-line text-muted ml-2 clickable"
                [ngbPopover]="'The layer will not be visible beyond these zoom levels.'"
                placement="bottom"
                container="body"
              ></i>
            </label>
            <div class="position-relative">
              <maplix-double-slider
                [min]="0"
                [max]="22"
                [minControl]="surveyLayer?.get('minZoom')"
                [maxControl]="surveyLayer?.get('maxZoom')"
                [themeColors]="themeColors"
              ></maplix-double-slider>
              <maplix-map-zoom-indicator [map]="mapService.map"></maplix-map-zoom-indicator>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">Visibility in legend</label>
            <ng-select
              [items]="visibilityItems"
              bindLabel="label"
              bindValue="value"
              formControlName="showOnLegend"
              [clearable]="false"
              [searchable]="false"
              appendTo=".layer-editor"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label class="form-label"
              >Element detail popup
              <i
                class="remixicon-question-line text-muted ml-2 clickable"
                [ngbPopover]="'Not applicable for (sub)layers with the \'Clustered\' style type.'"
                placement="bottom"
                container="body"
              ></i>
            </label>
            <ng-select
              [items]="popupItems"
              bindLabel="label"
              bindValue="value"
              formControlName="isSelectable"
              [clearable]="false"
              [searchable]="false"
              appendTo=".layer-editor"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="layerTabs"></div>
</div>
